<template>
  <div>
    <span class="profile-trigger" @click="toggleMenu" aria-haspopup="true" aria-controls="profile_tmenu" tabindex="0">
      <FontAwesomeIcon :icon="['fas', 'user']" /></span>
    <TieredMenu ref="menu" :model="menuItems" popup />
  </div>
</template>

<script>
export default {
  name: 'ProfileMenu',
  data() {
    return {
      menuItems: [
        { label: 'Home',  command: () => this.navigate('Home') },
        { label: 'Account',  command: () => this.navigate('Account') },
        { label: 'How To Use', url: 'https://www.youtube.com/watch?v=eSPaxwq0SEs', target: '_blank' },
        { label: 'Log out',  command: () => this.logout() }
      ]
    }
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    navigate(routeName) {
      this.$router.push({ name: routeName });
    },
    logout() {
      this.$emit('logout');
    }
  }
};
</script>

<style scoped>
.profile-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.profile-trigger:hover {
  background-color: #f0f0f0;
}
</style>