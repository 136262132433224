<template>
    <div v-if="editor" class="editor-menu">
        <div class="menu-group">
            <ExportMenu />
            </div>
            <!-- <button @click="toggleEditable" :class="{ 'is-active': editable }">
                Editable
            </button> -->

            <div class="menu-group">
        <div class="header-group" >
            <button v-tooltip.top="{ value: 'Heading', showDelay: 500}" class="dropdown-toggle" @click="toggleHeaderDropdown" :disabled="showRawMarkdown">
                <FontAwesomeIcon :icon="['fas', 'heading']" />

            </button>
            <div class="dropdown-menu" v-show="this.showHeaderDropdown">
                <button @click="setHeader(1)" class="dropdown-item" :style="{ fontSize: '2em' }">Header 1</button>
                <button @click="setHeader(2)" class="dropdown-item" :style="{ fontSize: '1.75em' }">Header 2</button>
                <button @click="setHeader(3)" class="dropdown-item" :style="{ fontSize: '1.5em' }">Header 3</button>
                <button @click="setHeader(4)" class="dropdown-item" :style="{ fontSize: '1.25em' }">Header 4</button>
                <button @click="setHeader(5)" class="dropdown-item" :style="{ fontSize: '1em' }">Header 5</button>
                <button @click="setHeader(6)" class="dropdown-item" :style="{ fontSize: '0.75em' }">Header 6</button>
            </div>
        </div>
        <button v-tooltip.top="{ value: 'Bold', showDelay: 500}" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'bold']" />
       </button>
       <button  v-tooltip.top="{ value: 'Italic', showDelay: 500}" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'italic']" />
       </button>
       
        <!-- <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }" :disabled="showRawMarkdown">
            strike
        </button>
        <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }" :disabled="showRawMarkdown">
            underline
        </button> -->
        <!-- <button @click="editor.chain().focus().toggleTight().run()"
            :class="{ 'is-active': editor.isActive({ tight:true }) }"
            :disabled="!editor.can().toggleTight()"
        >
            toggle tight
        </button> -->
        <!-- <button @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
            insertTable
        </button>
        <button @click="editor.chain().focus().addColumnBefore().run()" :disabled="!editor.can().addColumnBefore()">
            addColumnBefore
        </button>
        <button @click="editor.chain().focus().addColumnAfter().run()" :disabled="!editor.can().addColumnAfter()">
            addColumnAfter
        </button>
        <button @click="editor.chain().focus().deleteColumn().run()" :disabled="!editor.can().deleteColumn()">
            deleteColumn
        </button>
        <button @click="editor.chain().focus().addRowBefore().run()" :disabled="!editor.can().addRowBefore()">
            addRowBefore
        </button>
        <button @click="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()">
            addRowAfter
        </button>
        <button @click="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()">
            deleteRow
        </button>
        <button @click="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()">
            deleteTable
        </button> -->
       <!-- <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }" :disabled="showRawMarkdown">
           code
       </button> -->
       <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">
           clear marks
       </button> -->
       <!-- <button @click="editor.chain().focus().clearNodes().run()">
           clear nodes
       </button> -->
       <!-- <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
           paragraph
       </button> -->
       <!-- <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" :disabled="showRawMarkdown">
           h1
       </button>
       <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" :disabled="showRawMarkdown">
           h2
       </button>
       <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" :disabled="showRawMarkdown">
           h3
       </button>
       <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" :disabled="showRawMarkdown">
           h4
       </button>
       <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }" :disabled="showRawMarkdown">
           h5
       </button>
       <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }" :disabled="showRawMarkdown">
           h6
       </button> -->
       <button v-tooltip.top="{ value: 'Bullet List', showDelay: 500}" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'list-ul']" />
       </button>
       <button v-tooltip.top="{ value: 'Ordered List', showDelay: 500}" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'list-ol']" />
       </button>
       <div class="table-group">
            <button v-tooltip.top="{ value: 'Table', showDelay: 500}" @click="toggleTableGridSelector" :disabled="showRawMarkdown">
                <FontAwesomeIcon :icon="['fas', 'table']" />
            </button>
            <TableGridSelector :isOpen="showTableGridSelector" @insert-table="insertTable" @close="toggleTableGridSelector" />
        </div>
       <button v-tooltip.top="{ value: 'Code Block', showDelay: 500}" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'code']" />
       </button>
       <button v-tooltip.top="{ value: 'Quote', showDelay: 500}"  @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }" :disabled="showRawMarkdown">
           <FontAwesomeIcon :icon="['fas', 'quote-right']" />
       </button>
    </div>
       <!-- <button @click="editor.chain().focus().setHorizontalRule().run()" :disabled="showRawMarkdown">
           horizontal rule
       </button> -->
       <!-- <button @click="editor.chain().focus().setHardBreak().run()">
           hard break
       </button> -->
       <div class="menu-group">
       <button v-tooltip.top="{ value: 'Undo', showDelay: 500}" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().undo()">
           <FontAwesomeIcon :icon="['fas', 'undo']" />
       </button>
       <button v-tooltip.top="{ value: 'Redo', showDelay: 500}" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().redo()">
           <FontAwesomeIcon :icon="['fas', 'redo']" />
       </button>
       </div>

       <button @click="toggleRawMarkdown" class="raw-btn" :class="{ 'is-active':showRawMarkdown}">
                    Raw
        </button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExportMenu from './ExportMenu.vue'
import TableGridSelector from './TableGridSelector.vue';

    export default {
        data() {
            return {
                showHeaderDropdown: false,
                editable: true,
                showTableGridSelector: false,
                showExportModal: false
            };
        },
        props: {
            editor: Object,
            showRawMarkdown: Boolean,
        },
        components: {
            ExportMenu,
         FontAwesomeIcon,
         TableGridSelector
        },
        methods: {
            toggleHeaderDropdown() {
                this.showHeaderDropdown = !this.showHeaderDropdown;
            },
            setHeader(level) {
                this.editor.chain().focus()['toggleHeading']({ level }).run();
                this.showHeaderDropdown = false;
            },
            toggleRawMarkdown() {
                this.$emit('toggle-raw-markdown'); 
            },
            toggleEditable() {
                this.editable = !this.editable;
                this.editor.setOptions({editable: this.editable})
            }, 
            toggleTableGridSelector() {
                this.showTableGridSelector = !this.showTableGridSelector;
            },
            insertTable({ rows, cols }) {
                this.editor.commands.insertTable({ rows, cols, withHeaderRow: true });
                this.toggleTableGridSelector();
            },
            toggleExportModal() {
                this.showExportModal = !this.showExportModal;
            },
        }
    }
</script>

<style scoped>
.editor-menu {
  display: flex;
  position: relative; /* Added to position export modal correctly */
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px 20px;
  background-color: #f7f8fa;
  border-bottom: 1px solid #e4e9f0;
  position: relative;
}

.menu-group button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.editor-menu .menu-group {
    display: flex;
    gap: 10px;
}

.export-btn {
    width: 75px
}

.editor-menu .header-group,
.table-group {
  position: relative;
  display: inline-block;
}

.editor-menu .dropdown-toggle {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.editor-menu .dropdown-menu {
    top: 25px;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.editor-menu .dropdown-menu button {
  padding: 12px 16px;
  color: black;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
}

.editor-menu .dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.editor-menu button:hover {
  background-color: #3c7e6dd9;
  color: white;
}

.editor-menu button.is-active {
  background-color: #3c7e6d;
  color: white;
}

.editor-menu button:disabled {
  background-color: #e4e9f0;
  color: #aaa;
}

.raw-btn {
    position: absolute;
    bottom: -36px;   
    right: 15px;

    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #f7f8fa;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

</style>./ExportMenu.vue