/* global Paddle */

export default {
  install: (app, options) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
    document.head.appendChild(script);

    script.onload = () => {
      Paddle.Environment.set(options.environment); 
      Paddle.Initialize({ 
        token: options.token
      });
    };
  }
};