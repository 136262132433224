import { convertPdfToText } from './pdfService';
import { sendImportRequest } from './apiService';


export const plainTextFileTypes = [
    ".txt", ".md", ".json", ".xml", ".csv", ".log",
    ".yaml", ".yml", ".ini", ".conf", ".html", ".css",
    ".js", ".jsx", ".ts", ".tsx", ".java", ".c",
    ".cpp", ".h", ".hpp", ".cs", ".py", ".rb",
    ".php", ".go", ".lua", ".pl", ".scala", ".swift",
    ".kt", ".rs", ".dart", ".sh", ".bat", ".cmd",
    ".sql", ".ps1", ".vbs", ".r", ".groovy", ".m",
    ".asm", ".s", ".pas", ".bas", ".f", ".f90",
    ".f95", ".erl", ".hs", ".ml", ".mli", ".sml",
    ".thy", ".tex", ".cls", ".sty", ".bib", ".lisp",
    ".clj", ".vim", ".emacs", ".ex", ".exs", ".eex",
    ".leex", ".heex", ".elm", ".toml", ".twig", ".jinja",
    ".jinja2", ".ejs", ".erb", ".pug", ".hbs", ".mustache",
    ".vue", ".svelte", ".mdx", ".rst", ".adoc", ".asciidoc",
    ".wasm", ".scss", ".sass", ".rs", ".less", ".graphql",
    ".mdx", ".tf", ".hcl", ".nim", ".bal", ".zig", ".v", ".sol",
    ".prisma", ".bicep", ".cue", ".dhall", ".rkt", ".scrbl",
    ".wat", ".webmanifest", ".dockerfile", ".tfstate", ".tfvars",
    ".feature", ".ipynb", ".dox",".swagger", ".openapi",  ".api",
    ".rst", ".adoc", ".asciidoc",  ".wiki",   ".texi",  ".dita",
     ".ditamap", ".docbook",  ".pod",  ".roff", ".nroff", ".man", 
    ".markdown", ".ehtml", ".xhtml", ".htm", ".html", ".shtml",
    ".php3", ".php4", ".php5", ".php", ".inc", ".asp", ".aspx",
  ];

  export const pandocFileTypes = ['.docx','.rtf', '.latex']

  export const otherFileTypes = ['.pdf']

  export const allFileTypes = [...plainTextFileTypes, ...pandocFileTypes, ...otherFileTypes]

  export function isPlainTextFile(input, fullName = false) {   
    let extension = input;
    if (fullName)
        extension = input.split('.').pop().toLowerCase();
    
    return plainTextFileTypes.includes('.' + extension);
  }

  export function isPandocFile(input, fullName = false) {   
    let extension = input;
    if (fullName)
        extension = input.split('.').pop().toLowerCase();
    
    return pandocFileTypes.includes('.' + extension);
  }

  export async function extractPlainTextFromFile(file, getAccessTokenSilently) {
    const extension = file.name.split('.').pop().toLowerCase();
  
    if (extension === 'pdf') {
      return await convertPdfToText(file);
    } else if (isPandocFile(extension)) {
      const token = await getAccessTokenSilently();
      const result = await sendImportRequest(token, file);
      return result.content;
    } else if (isPlainTextFile(extension)) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsText(file);
      });
    } else {
      throw new Error(`Unsupported file type: .${extension}`);
    }
  }