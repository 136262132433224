<template>
  <div class="editor-container">
  <DocumentMenuBar :editor="editor" :showRawMarkdown="showRawMarkdown" @toggle-raw-markdown="toggleRawMarkdown" />
  <EditorContent v-if="!showRawMarkdown" class="editor-content" :editor="editor" />
  <textarea v-else v-model="this.store.state.activeItem.content" class="raw-markdown-editor"></textarea>
  </div>
</template>

<script>
import { store } from '../../../services/store.js'
import { eventBus } from '../../../services/eventBus';
import {contentToDocList, docListToContent, applyEdits, postProcessEditText} from '../../../services/applyEditsService.js'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { Markdown } from 'tiptap-markdown';

import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import InlineTableHeader from './TipTap/Extensions/InlineTableHeader.js';
import InlineTableCell from './TipTap/Extensions/InlineTableCell.js';
import TextAlign from '@tiptap/extension-text-align'

// import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
// import HardBreak from '@tiptap/extension-hard-break'
// import Youtube from "@tiptap/extension-youtube";
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import DocumentMenuBar from "./DocumentMenuBar.vue";
import Highlight from "@tiptap/extension-highlight";
//  import Container from "./TipTap/Extensions/container.js";
import EditBlock from "./TipTap/Extensions/editBlock.js";
import RemoveEditClipboard from "./TipTap/Extensions/removeEditClipboard.js";
import { common, createLowlight} from 'lowlight';
import RawInsertNode from './TipTap/Extensions/rawInsertNode.js';
import RawDeleteNode from './TipTap/Extensions/rawDeleteNode.js';

  const lowlight = createLowlight(common);


export default {
  name: 'DocumentEditor',
  components: {
    EditorContent,
    DocumentMenuBar
  },
  data() {
    return {
      editor: null,
      showRawMarkdown: false, 
      store
    }
  },
  mounted() {
    this.editor = new Editor({
                extensions: [
                EditBlock,
                RemoveEditClipboard,
                RawInsertNode,
                    RawDeleteNode,
                    Markdown.configure({
                         transformPastedText: true,
                        transformCopiedText: true,
                    }),
                    //  Container,
                    StarterKit.configure({
                        codeBlock: false,
                    }),
                    Table.configure({
                        resizable: false,
                    }),
                    TableRow,
                    InlineTableHeader,
                    InlineTableCell,
                    TextAlign.configure({
                      types: ['tableCell', 'tableHeader'],
                    }),
                    // Underline,
                    Image.configure({
                        inline: true,
                        
                        allowBase64: true
                    }),
                    Link,
                    Highlight,
                    TaskList,
                    TaskItem,
                    // HardBreak,
                    CodeBlockLowlight.configure({
                      lowlight,
                      defaultLanguage: 'markdown',
                      HTMLAttributes: {
                        class: 'hljs',
                      },
                    }),
                    // CodeBlockLowlight.configure({
                    //     lowlight,
                    // }),
                    // Youtube,
       
                ],
                
                onUpdate: () => {
                 let content = this.editor.storage.markdown.getMarkdown();
                  store.state.activeItem.content = content;
                },
                editorProps: {
                    attributes: {
                        class: 'editor-body',
                    },
                }
            });

        store.editor = this.editor;

        this.refreshContent();   

    eventBus.on('EditStream.Started', this.streamEdits);
    eventBus.on('Divider.Resized', this.handleDividerResized);
    eventBus.on('ActiveItem.Set', this.refreshContent);
    eventBus.on('Edit.Applied', this.rerenderAfterEdit);
  },
  beforeUnmount() {
    this.editor.destroy();
    
    eventBus.off('EditStream.Started', this.streamEdits);
    eventBus.off('Divider.Resized', this.handleDividerResized);
    eventBus.off('ActiveItem.Set', this.refreshContent);
    eventBus.off('Edit.Applied', this.rerenderAfterEdit);
  },
  methods: {
    streamEdits() {
      let content = this.editor.storage.markdown.getMarkdown();
      let documentList = contentToDocList(content)
      let initialDocLength = documentList.length
      let edits = store.streamData.edits;
      let editTexts =  store.state.editTexts ? store.state.editTexts : [];
      
      if (editTexts.length > 50) 
        editTexts  = editTexts.slice(-50) // only allow 50 stored editTexts

      const interval = setInterval(() => {
          ({documentList, editTexts} = applyEdits(initialDocLength, documentList, edits, editTexts));
          store.state.editTexts = editTexts;
          let newContent = docListToContent(documentList);
          if (newContent !== this.store.state.activeItem.content) {
            this.setContent(newContent);
          }
          else {
            this.renderEdits();
          }

          if (edits.allDone) {
            store.state.editTexts.forEach(editText => {

              if (!editText.postProcessed) {
                editText = postProcessEditText(editText);
                console.log(editText)
              }
                
              });

              store.state.activeItem.content =  this.editor.storage.markdown.getMarkdown();
              clearInterval(interval); // Clear the interval if all edits are done
          }
      }, 300); 
    },

    handleDividerResized() {
      window.dispatchEvent(new Event('resize')); // :(
    },
    refreshContent() {
      if(store.state.activeItem.content == null) {
        store.state.activeItem.content = '';
      }
      this.setContent(store.state.activeItem.content);   
    },
    rerenderAfterEdit(content) {
      const editorContentContainer = this.$el.querySelector('.editor-content');

      const currentScrollPosition = editorContentContainer ? editorContentContainer.scrollTop : 0;

      this.setContent(content);
      
      this.$nextTick(() => {
        if (editorContentContainer) {
          editorContentContainer.scrollTop = currentScrollPosition;
        }
      });
    },

    setContent(content){
      if (!content) {
        this.editor.commands.setContent('', true);
        store.state.activeItem.content = ''
      }
      else {
        this.editor.commands.setContent(content);
        store.state.activeItem.content = content
      }
      this.renderEdits();
    },
    toggleRawMarkdown() {
        this.showRawMarkdown = !this.showRawMarkdown;
        if (!this.showRawMarkdown) {
          this.setContent(this.store.state.activeItem.content);
        }
      },

      renderEdits() {
        const insertNodeRef = this.editor.schema.nodes.rawInsert;
        const deleteNodeRef = this.editor.schema.nodes.rawDelete;
        const editTexts = this.store.state.editTexts;

        let changes = [];

        // Step 1: Collect Changes
        this.editor.state.doc.descendants((node, pos) => {
          editTexts.forEach(editText => {
            if (node.type.name === 'edit' && node.attrs.uid == editText.id) {
              let deletionText = editText.delete;
              let insertText = editText.insert;
              let fragment = [];

              if (deletionText && deletionText.trim().length !== 0) {
                fragment.push(deleteNodeRef.create({ uid: editText.id }, this.editor.schema.text(deletionText)));
              }
              if (insertText && insertText.trim().length !== 0) {
                fragment.push(insertNodeRef.create({ uid: editText.id }, this.editor.schema.text(insertText)));
              }

              if (fragment.length > 0) {
                changes.push({ pos, node, fragment });
              }
            }
          });
        });

        // Step 2: Sort Changes in Reverse Order
        changes.sort((a, b) => b.pos - a.pos);

        // Initialize a single transaction
        let transaction = this.editor.state.tr;

        // Step 3: Apply Changes
        changes.forEach(change => {
          const newNode = change.node.type.create(change.node.attrs, change.fragment, change.node.marks);
          transaction = transaction.replaceWith(change.pos, change.pos + change.node.nodeSize, newNode);
        });

        if (!transaction.docChanged) {
          return;
        }

        // Dispatch the accumulated transaction at the end
        this.editor.view.dispatch(transaction);
      }
  }
}
</script>

<style>

.editor-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: auto;
}
.raw-markdown-editor {
  height: 100%;
  font-family: monospace;
  font-size: 14px;
  padding: 30px 12%
}

.ProseMirror {
  overflow-wrap:anywhere;
  flex-grow:1;
  padding: 10px;
  outline: none;

}

blockquote p {
  margin-left: 1em;
  border-left: 4px solid #eee;
  padding-left: 1em;
  font-style: italic;
  color: #666;
}

.editor-content {
  overflow-y: scroll;
  flex: 1; /* Take up the full width of the container */
  display: flex;
  flex-direction: column;
  justify-content: stretch; /* Stretch vertically */
  border: none;
  padding: 0 12%;
}

.editor-content  ::selection {
  background-color: #48cd938a;
  color: black;
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
  margin-bottom: 8px;
}

th,
td {
  padding: 8px;
  border: 1px solid #e4e9f0;
  /* text-align: left; */
}

th {
  background-color: #f7f8fa;
}


.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #333;
    background: #f7f8fa;
}

.hljs-comment,
.hljs-quote {
    color: #998;
    font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: #333;
    font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
    color: #008080;
}

.hljs-string,
.hljs-doctag {
    color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
    color: #900;
    font-weight: bold;
}

.hljs-subst {
    font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
    color: #458;
    font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
    color: #000080;
    font-weight: normal;
}

.hljs-regexp,
.hljs-link {
    color: #009926;
}

.hljs-symbol,
.hljs-bullet {
    color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
    color: #0086b3;
}

.hljs-meta {
    color: #999;
    font-weight: bold;
}

.hljs-deletion {
    background: #fdd;
}

.hljs-addition {
    background: #dfd;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}
</style>