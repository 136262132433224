<template>
  <div class="credit-modal-overlay">
    <div class="credit-modal">
      <p>{{ message }}</p>
      <button v-if="action" @click="action">Request More Credits</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditModal',
  props: {
    message: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      default: null
    }
  }
};
</script>

<style scoped>
.credit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.credit-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.credit-modal button {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.credit-modal button:hover {
  background-color: #3c7e6dd9;
  color: white;
}
</style>