
export function applyEdits(initialDocLength, documentList, edits, editTexts) {
    if (edits.done.length === 0 && Object.keys(edits.inProgressEdit).length === 0) {
        return { documentList,editTexts }; 
    }

    let allEdits = edits.done;
    if (Object.keys(edits.inProgressEdit).length !== 0){
        allEdits = [...edits.done, edits.inProgressEdit];
    }

    let editedLines = new Set();

    allEdits.forEach((edit, editIndex) => {

        //we use editIndex as a temporary primary key for the edit. When the edit is marked done its given an Id 
        let editId = editIndex;
        if (edit.done) {
            editId = edit.id;

            let temporarEditText = editTexts.find(editText => editText.id == editIndex)
            if (temporarEditText){
                temporarEditText.id = editId;
            }
        }

        let editItem = ['edit:'+editIndex, `::: edit [${editId}]\n:::`]; 
        if (edits.isInitial) {
            editItem = ['edit:'+editIndex, edit.text]; // just write straight into the doc
        }
        let existingEditIndex = documentList.findIndex(item => item[0] === editItem[0]);

        // quickly update existing edits and return
        if (existingEditIndex !== -1) {
            documentList[existingEditIndex] = editItem;

            let editText = editTexts.find(editText => editText.id == editId)
            editText.insert = edit.text
            return;
        }

        //-- begin processing new edit --

        // if document is empty - every edit is an insert
        if (initialDocLength === 0 ){
            edit.type = 'insert';
            edit.line = -1;
        }

        // check replace is valid (cant replace twice)
        if (edit.type === 'replace') {
            let editRange = range(edit.startLine, edit.endLine);
            if (editRange.some(lineNum => editedLines.has(lineNum))) {
                return; 
            }
            editRange.forEach(lineNum => editedLines.add(lineNum));
        }

        let insertIndex, replaceLength = 0;

        if (edit.type === 'insert') {
            if (edit.line == -1 || edit.line > initialDocLength) {
                documentList.push(editItem);
                editTexts.push({
                    id: editId,
                    insert: edit.text,
                    delete: '',
                    postProcessed: false
                });
                return; 
            }
            else if (edit.line <= 1 ) {
                insertIndex = 0;
            }
            else {
                insertIndex = findInsertIndex(documentList, edit.line);
            }
        } else if (edit.type === 'replace') {
            insertIndex = findInsertIndex(documentList, edit.startLine);
            replaceLength = edit.endLine - edit.startLine + 1;
        }

        let deletedLines = documentList.slice(insertIndex, insertIndex + replaceLength).map(item => item[1]);
        editTexts.push({
            id: editId,
            insert: edit.text,
            delete: deletedLines.join('\n'),
            postProcessed: false
        });

        documentList.splice(insertIndex, replaceLength, editItem);
    });

    return { documentList, editTexts }; 
}

function removeCsvFromInsert(input) {
    const lines = input.split('\n');

    const processedLines = lines.map(line => {
      return line.replace(/^\d+,/, '');
    });

    return processedLines.join('\n');
  }

  function removeEnclosingQuotes(input) {
    // Check if input starts and ends with a quote
    if (input.startsWith('"') && input.endsWith('"')) {
        // Remove the first and last character from the string
        return input.substring(1, input.length - 1);
    }
    return input;
}

export function postProcessEditText(editText) {
    editText.insert = removeEnclosingQuotes(editText.insert);
    editText.insert = removeCsvFromInsert(editText.insert);
    editText.postProcessed = true;
    return editText;
}

export function contentToDocList(content) {
    if (typeof content === 'undefined' || content === null || content.length === 0 || content === '\n'){
        return []
    }

    
    let lines = content.split('\n');
    return lines.map((line, index) => [index + 1, line]);
}

export function docListToContent(docList) {
    if (typeof docList === 'undefined' || docList === null|| docList.length === 0 ){
        return ''
    }

    let content = docList.map(row => row[1]).join('\n');
    return content;
}

function range(start, end) {
    return Array(end - start).fill().map((_, idx) => start + idx);
}

function findInsertIndex(document, lineNumber) {
    for (let i = 0; i < document.length; i++) {
        if (document[i][0] == lineNumber) {
            return i;
        }
    }
    throw new Error("Start Index not found in the document.");
}
