<template>
  <transition name="toast">
    <div v-if="visible" :class="['toast', `toast-${type}`]">
      {{ message }}
    </div>
  </transition>
</template>

<script>
// import { store } from '../services/store.js';
import { eventBus } from '../services/eventBus';

export default {
  name: 'ToastPopup',
  data() {
    return {
      visible: false,
      message: '',
      type: 'info',
      timeout: null
    };
  },
  mounted() {
    eventBus.on('Toast.Show', this.showToast);
  },
  beforeUnmount() {
    eventBus.off('Toast:Show', this.showToast);
  },
  methods: {
    showToast({ message, type }) {
      this.message = message;
      this.type = type;
      this.visible = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, 1500);
    }
  }
};
</script>

<style scoped>
.toast {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px 20px;
  border-radius: 10px;

  color: white;
  font-size: 14px;
  z-index: 10000;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}

.toast-success {
  background-color: #3c7e6db0;
}

.toast-error {
  background-color: #e74c3c;
}

.toast-enter-active, .toast-leave-active {
  transition: opacity 0.5s;
}

.toast-enter-from, .toast-leave-to {
  opacity: 0;
}
</style>