import { Node, mergeAttributes} from '@tiptap/core';
import markdownitContainer from "markdown-it-container";
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import EditBlock from '../EditBlock.vue';


export default Node.create({
  name: 'edit',

  group: 'block',

  content: 'block+',

  defining: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'edit-block',
      },
    };
  },

  addStorage() {
    return {
      markdown: {
        serialize(state, node) {
          state.write(`::: edit [${node.attrs.uid || 'undefined-uid'}]\n`);
          // state.renderContent(node); // this prevents rendering injecting contents back to markdown
          state.flushClose(1);
          state.write(':::');
          state.closeBlock(node);
        },
        parse: {
          setup(markdownit) {
            markdownit.use(markdownitContainer, 'edit', {
              validate: params => params.trim().match(/^edit\s+\[([^\]]+)\]$/),
              render: (tokens, idx) => {
                const m = tokens[idx].info.trim().match(/^edit\s+\[([^\]]+)\]$/);
                if (tokens[idx].nesting === 1) {
                  return `<div class="edit-block" data-uid="${m[1]}">\n`;
                } else {
                  return '</div>\n';
                }
              },
            });
          },
        }
      }
    };
  },

  addKeyboardShortcuts() {
    return {
      // only needed if rendering full html doc 
      // Enter: () => {
      //   const { state, } = this.editor.view;
      //   const { selection } = state;
      //   const { $from, } = selection;

      //   // dont run split block on the parent block if your inside an edit block
      //   if ($from.node($from.depth - 1).type.name === 'edit' ) {
      //       this.editor.chain().focus().splitBlock().run()
      //       return true; 
      //   }
        
      //   return false;
      // }
    };
},


addAttributes() {
  return {
    uid: {
      default: null,
      parseHTML: element => element.getAttribute('data-uid'),
      renderHTML: attributes => ({
        'data-uid': attributes.uid,
      }),
    },
  };
},

  

  parseHTML() {
    return [
      {
        tag: 'div.edit-block',
        getAttrs: element => ({
          uid: element.getAttribute('data-uid'),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['vue-component', mergeAttributes(HTMLAttributes)]
  },

addNodeView() {
    return VueNodeViewRenderer(EditBlock);
  },

});
