<template>
  <div class="auth-prompt-container">
    <PvDialog v-model:visible="showErrorDialog" header="Thanks for using Mynt!" :modal="true">
      <div class="error-modal">
      <p>{{ errorMessage }}</p>
      <PvButton label="Done" @click="login" />
    </div>
    </PvDialog>
    <div class="auth-prompt">
      <h1>Welcome to Mynt</h1>
      <p>AI super-charged writing</p>
      <button @click="login" class="auth-button">Login</button>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { ref, onMounted } from 'vue';
import PvDialog from 'primevue/dialog';
import PvButton from 'primevue/button';

export default {
  name: 'AuthPrompt',

  components: {
    PvDialog,
    PvButton,
  },
  setup() {
    const { loginWithRedirect } = useAuth0();
    const showErrorDialog = ref(false);
    const errorMessage = ref('');

    onMounted(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('error') === 'access_denied') {
        errorMessage.value = decodeURIComponent(params.get('error_description'));
        showErrorDialog.value = true;
      }
    });

    return {
      login: () => loginWithRedirect(),
      signup: () => loginWithRedirect({screen_hint: 'signup'}),
      showErrorDialog,
      errorMessage,
    };
   }
  };
</script>

<style scoped>
.auth-prompt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 46px);
  background: #f8fcf9;
}

.auth-prompt {
  text-align: center;
  max-width: 400px;
  margin-top: -70px;
}

.auth-prompt h1 {
  font-size: 2.5rem;
  color: #3c7e6d;
  margin-bottom: 20px;
}

.auth-prompt p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.auth-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  background-color: #3c7e6dd9;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.auth-button:hover {
  background-color: #3c7e6d;
}

.error-modal {
  justify-content: space-evenly;
    display: flex;
    flex-direction: column;
}
</style>