import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { DOMParser } from '@tiptap/pm/model';
import { Slice, Fragment} from '@tiptap/pm/model';

export default Extension.create({
  name: 'removeEditClipboard',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('removeEditClipboard'),
        props: {
          transformCopied(slice) {
            return new Slice(
              removeEditAndConvertMarkdown(slice.content),
              slice.openStart,
              slice.openEnd
            );
          },
          
        },
      }),
    ];
  },
});


function elementFromString(value) {
  // add a wrapper to preserve leading and trailing whitespace
  const wrappedValue = `<body>${value}</body>`

  return new window.DOMParser().parseFromString(wrappedValue, 'text/html').body
}
  function removeEditAndConvertMarkdown(fragment) {
    let newFragment = Fragment.empty; 
  
    const processNode = (node) => {
      // if edit block - render the raw markdown text into html before adding to clipboard
      if (node.type.name === 'edit') {
        node.content.forEach(child => {
          if (child.type.name === 'pre.insert' || child.type.name === 'pre.delete') {
            const htmlContent = this.editor.storage.markdown.parser.parse(child.textContent, { inline: true });
            const domElement = elementFromString(htmlContent); 
            const docFragment = DOMParser.fromSchema(this.editor.schema).parse(domElement);
            newFragment = newFragment.append(docFragment);
          }
        });
        return null; // Do not append the edit block itself to the new fragment.
      } else if (node.isLeaf) {
        return Fragment.from(node); // Leaf nodes are returned directly.
      } else {
        const processedContent = removeEditAndConvertMarkdown(node.content); // Recursively process the content of non-leaf nodes.
        return Fragment.from(node.copy(processedContent)); 
      }
    };
  
    fragment.forEach(child => {
      const processedChild = processNode(child);
      if (processedChild) {
        newFragment = newFragment.append(processedChild); // Append processed children to the new fragment.
      }
    });
  
    return newFragment; // Return the reconstructed fragment.
  }