<template>
    <div class="reference-item" >

        <div class="reference-card" :class="{ 'active': store.state.activeItem && store.state.activeItem.id === this.reference.id}" @click="setActive">
            <div class="reference-info">
                <FontAwesomeIcon :icon="['fas', 'file-alt']" class="file-icon"  :class="{ active: !reference.selected }" />
                <div class="reference-name ">{{ reference.name }}</div>
            </div>

            <div class="reference-buttons">
              <button v-tooltip.top="{value: 'Edit Reference', showDelay: 50}" class="reference-button"  :class="{ active: reference.editing }" @click.stop="toggleEditing">
                    <FontAwesomeIcon :icon="['fas', 'pencil-alt']" />
                </button>

                <button v-tooltip.top="{ value: reference.selected ? 'Hide from AI' : 'Unhide from AI', showDelay: 50}" class="reference-button"  :class="{ active: !reference.selected }" @click.stop="toggleSelected">
                    <FontAwesomeIcon v-if="reference.selected" :icon="['fas', 'eye']" />
                    <FontAwesomeIcon  v-else :icon="['fas', 'eye-slash']" />
                </button>
                <button class="reference-button" @click.stop="deleteReference">
                    <FontAwesomeIcon :icon="['far', 'trash-can']" />
                </button> 
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { store, setActiveItem } from '../../services/store.js';
  
  export default {
    name: 'ReferenceItem',
    components: {
      FontAwesomeIcon
    },
    props: {
        index: Number
    },
    data() {
    return {
        store,
        reference: store.state.references[this.index] 
    }
    },
    methods: {
        setActive() {
            setActiveItem(this.reference.id)
          },
        toggleSelected() {
            this.reference.selected = !this.reference.selected;

            let deepRef =  store.state.references[this.index];
            deepRef.selected = this.reference.selected;

            if (store.state.activeItem && this.reference.id &&store.state.activeItem.id === this.reference.id) {
                store.state.activeItem.selected = this.reference.selected ;
          }
        },
        toggleEditing() {
            this.reference.editing = !this.reference.editing;

            let deepRef =  store.state.references[this.index];
            deepRef.editing = this.reference.editing;

            if (store.state.activeItem && this.reference.id &&store.state.activeItem.id === this.reference.id) {
                store.state.activeItem.editing = this.reference.editing ;
                this.reference.content = store.state.activeItem.content;
                deepRef.content = this.reference.content;
            }

        },
        deleteReference() {
            if (!store.aiInProgress){
                this.store.state.references.splice(this.index, 1);
                this.store.state.activeItem = null;
            }
        },
    }
  };
  </script>
  
  <style scoped>

.file-icon {
  font-size: 1.5rem;
  color: #3c7e6dbf;
}

.file-icon.active {
  color: #999;
}

.reference-info {
  overflow: hidden;
  line-break: anywhere;
}

.reference-name {
  font-size: 0.75rem;
  color: #333;
  margin-top: 5px;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.reference-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  margin: 10px 0;
  min-height: 90px;
  /* box-shadow: 0px 2px 4px rgba(0,0,0,0.1); */
}

.reference-card.active {
  background-color: #f7f8fa;
}
.reference-card:hover {
  background-color: #3c7e6d24;
}

.reference-button {
  margin-top: 8px;
  padding-top: 2px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.reference-button:hover {
  color: #333;
}

.reference-button.active {

border: none;
margin: 0;
cursor: pointer;
transition: background-color 0.3s ease;
flex: 1;
border-radius: 7px;
background-color: #3c7e6dbf;
color: white;
}

  </style>