import { Node } from '@tiptap/core';

export default Node.create({
  name: 'rawDelete',
  content: 'text*',
  group: 'block',
  code: true,
  defining: true,
  marks: 'highlight',
  addAttributes() {
    return {
      class: {
        default: 'delete',
      },
      uid: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{
      tag: 'pre.delete',
      getAttrs: dom => ({
        class: dom.getAttribute('class'),
        'data-uid': dom.getAttribute('data-uid'),
      }),
    }];
  },
  renderHTML({ node }) {
    return ['pre', { class: node.attrs.class, 'data-uid': node.attrs.uid, contentEditable: 'false' }, 0];
  },
});