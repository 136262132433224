<template>
    <div class="model-menu-container">
        
      <span class="model-menu-trigger" @click="toggleMenu" aria-haspopup="true" aria-controls="model_tmenu" tabindex="0">
        AI Model: {{store.state.model.displayName }}
      </span>
      <PvMenu ref="menu" :model="modelItems" popup />
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router'; 
  import { eventBus } from '../services/eventBus';
  import { useAuth0 } from '@auth0/auth0-vue';
  import { getModels } from '@/services/apiService';
  import { store } from '@/services/store';
  
  export default {
    name: 'ModelMenu',
    setup() {
      const { getAccessTokenSilently } = useAuth0();
      const modelItems = ref([]);
      const router = useRouter(); 

      function formatModelDisplayName(model){
        if (model.free) {
            return `${model.displayName} (Free)`;
        } else {
            // Assuming inputCost is per million tokens, format the price with two decimal places
            const pricePerMillionTokens = (model.inputCost*0.75 + model.outputCost*0.25).toFixed(2);
            if (model.new) {
              return `New - ${model.displayName} (≈ $${pricePerMillionTokens} / Mil Tokens)`;
            }
            return `${model.displayName} (≈ $${pricePerMillionTokens} / Mil Tokens)`;
        }
    }


    async function changeModel(model) {
      if (!model.free && store.account.balance_dollars <= 0) {
        eventBus.emit('Toast.Show', { message: 'Purchase AI Credits to use paid models', type: 'error' });
        router.push({ name: 'Account' });
        return;
      }
      store.state.model = model;
    }
  
      onMounted(async () => {
        try {
          const token = await getAccessTokenSilently();
          const models = await getModels(token);
          modelItems.value = models.map(model => ({

            label: formatModelDisplayName(model),
            command: () => changeModel(model)
          }));
        } catch (error) {
          console.error('Error fetching models:', error);
        }
      });

      return {
        formatModelDisplayName,
        modelItems
      };
    },
    data() {
    return {
        store
    }
    },
    methods: {
      toggleMenu() {
      this.$refs.menu.toggle(event);
    },
    changeModel(model){ 
      if (!model.free && store.account.balance_dollars <= 0){
                console.log('hey!')
                this.$router.push({ name: 'Account' });
                return
              }

        store.state.model = model
    }
    }
  };
  </script>
  
  <style scoped>
  .model-menu-container {
    cursor: pointer;
  }
  
  .model-menu-trigger {
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .model-menu-trigger:hover {
    color: #3c7e6d;
    background-color: #f0f0f0;
  }
  </style>