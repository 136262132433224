<template>
    <div class="app-container">
      <div class="no-mobile" v-if="this.store.isMobile">
          Mobile not currently supported. Please switch to a larger display.
      </div>
      <SplitterMain  style="height: 100%; width:100%" v-else>
        <SplitterPanel :size="33"   >
          <ChatPanel style="height: 100%"  />
        </SplitterPanel>
        <SplitterPanel :size="50"   >
          <CentrePanel style="height: 100%"/>
        </SplitterPanel >
        <SplitterPanel  :size="17">
          <ReferencePanel />
        </SplitterPanel> 
      </SplitterMain>      
    </div>
  </template>
  
  <script>
  import ChatPanel from '../components/ChatPanel/ChatPanel.vue';
  import CentrePanel from '../components/CentrePanel/CentrePanel.vue';
  import ReferencePanel from '../components/ReferencePanel/ReferencePanel.vue';
  import { store } from '../services/store';
  
  export default {
    name: 'AppView',
    components: {
      ChatPanel,
      CentrePanel,
      ReferencePanel
    },
    data() {
      return {
        showCreditModal: false,
        creditModalMessage: '',
        creditModalAction: null,
        creditModalButtonText: 'Request More Credits',
        currentView: 'chat',
        store
      };
    },
    created() {
      this.checkIfMobile();
      window.addEventListener('resize', this.checkIfMobile);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkIfMobile);

    },
    methods: {
    checkIfMobile() {
      if (window.innerWidth < 850) {
        store.isMobile = true;
        if (store.currentAppView === 'both') {
            store.currentAppView = 'chat'; 
        }
      } else {
        store.isMobile = false;
        store.currentAppView = 'both'; 
      }
    },
    setView(view) {
        store.currentAppView = view;
    },
  },
  };
  </script>

  <style>  
    [data-p-gutter-resizing="true"] {
      background: #3c7e6d85;
    } 
</style>
  
  <style scoped >

.app-container {
  display: flex;
  flex-direction: row;
  height: calc(100svh - 46px); 
}

  .no-mobile {
    padding: 30px;
  }


  /* todo: this is bigger than normal phone switch points? recommended was 768 */
  @media (min-width: 850px) { 
    .app-container {
      grid-template-columns: 300px 1fr;
      grid-template-rows: 1fr;
    }

    .chat-panel-component {
      display: block;
    }

    .file-handler-component {
      display: block;
    }
  }

  .toggle-view {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #3c7e6dd9;
    color: white;
    cursor: pointer;
  }

  </style>