<template>
  <div>
    <PvButton icon="pi pi-fw pi-plus" aria-label="Add Reference" @click="toggleMenu" class="add-reference-trigger" />    
    <TieredMenu ref="menu" :model="menuItems" popup />
    <PvDialog v-model:visible="this.showFileModal" modal header="Upload File" :style="{ width: '25rem' }">
      <FileUpload mode="basic" :multiple="true" :maxFileSize="10000000" customUpload auto @uploader="loadReferenceFile" :accept="this.acceptFileTypes" >
        <template #empty>
            <p>Drag and drop here to upload.</p>
        </template>
    </FileUpload>
    </PvDialog>
    <PvDialog v-model:visible="this.showWebsiteModal" modal header="Add Website Reference" :style="{ width: '30rem' }">
    <div class="url-input-container">
      <input type="text" v-model="this.websiteUrl" placeholder="Enter website URL" class="url-input"/>
    </div>
    <template #footer>
      <button class="add-button" @click="addWebsiteReference">Add</button>
    </template>
  </PvDialog>
  <PvDialog v-model:visible="showManualModal" header="Add Manual" :style="{ width: '50vw' }">
    <div>
      <div class="field">
        <label for="title">Title</label>
        <InputText type="text" id="title" v-model="manualTitle" />
      </div>
      <div class="field">
        <label for="content">ReferenceContent</label>
        <TextArea id="content" v-model="manualContent"></TextArea>
      </div>
      <PvButton label="Add" @click="addManualReference" />
    </div>
    </PvDialog>
  </div>
</template>

<script>
import { eventBus } from '../../services/eventBus';
import { extractPlainTextFromFile, allFileTypes } from '../../services/fileService';
import { extractWebData } from '../../services/apiService';
import { useAuth0 } from '@auth0/auth0-vue';
import { store, setActiveItem } from '../../services/store.js';

export default {
  name: 'AddReferenceMenu',
  setup() {
  const { getAccessTokenSilently } = useAuth0();
  return { getAccessTokenSilently };
},
  data() {
    return {
      menuItems: [ 
        { label: 'File', icon: 'pi pi-fw pi-file', command: this.toggleFileModal },
        { label: 'Website', icon: 'pi pi-fw pi-globe', command: this.toggleWebsiteModal},
        { label: 'Manual', icon: 'pi pi-fw pi-pencil', command: this.toggleManualModal },
        { label: 'GitHub Repository (Coming Soon)', icon: 'pi pi-fw pi-github', command: () => eventBus.emit('AddReference.GitHub') },
      ],
      showFileModal: false,
      showWebsiteModal: false,
      showManualModal: false,
      websiteUrl: '',
      acceptFileTypes: allFileTypes.join(','),
      manualTitle: '',
      manualContent: '',
    }
  },

  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    toggleWebsiteModal() {
      this.showWebsiteModal = !this.showWebsiteModal;
    },
    toggleFileModal() {
      this.showFileModal = !this.showFileModal;
    },
    toggleManualModal() {
      this.showManualModal = !this.showManualModal;
    },
    addManualReference() {
      const id = crypto.randomUUID();
      const newReference = {
            id: id,
            type: 'ref-plain-text',
            name: this.manualTitle,
            content: this.manualContent,
            selected: true,
          };
          store.state.references.push(newReference);
          setActiveItem(id);

          
          this.manualContent = '';
          this.manualTitle = '';
          this.showManualModal = false;
    },
    async addWebsiteReference(websiteUrl) {
      if (this.websiteUrl.trim()) {
        this.websiteUrl = this.websiteUrl.includes('://') ? this.websiteUrl : `http://${this.websiteUrl}`;
        const token = await this.getAccessTokenSilently();

        let response = await extractWebData(token, this.websiteUrl);

        if (response.content.trim()) {
          const id = crypto.randomUUID();
          const newReference = {
            id: id,
            type: 'ref-website',
            name: this.websiteUrl,
            content: response.content,
            selected: true,
          };
          store.state.references.push(newReference);
      }
      else {
        // todo: toast - no content extracted
      }
      websiteUrl.value = '';
      this.showWebsiteModal= false;
    }
    },
    async loadReferenceFile(event) {
      for (const file of event.files) {
        try {
          const text = await extractPlainTextFromFile(file, this.getAccessTokenSilently);
          const id = crypto.randomUUID();
          const newReference = {
            id: id,
            type: 'ref-plain-text',
            name: file.name,
            content: text,
            selected: true,
          };
          store.state.references.push(newReference);
          setActiveItem(id);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
      this.showFileModal = false;
    }


    }
}
</script>

<style scoped>
.add-reference-trigger {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.url-input-container {
  margin-bottom: 20px;
}

.url-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #3c7e6d;
  color: white;
  cursor: pointer;
}

.field {
  margin-bottom: 1rem;
}

.field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.field input[type="text"],
.field textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>