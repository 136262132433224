import { createRouter, createWebHistory } from 'vue-router';
import AppView from '../views/AppView.vue';
import AccountManagement from '../views/AccountManagement.vue';
import { useAuth0 } from '@auth0/auth0-vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: AppView
  },
  {
    path: '/login',
    name: 'login',
    component: AppView,
    beforeEnter: () => {
        const { loginWithRedirect } = useAuth0();

        loginWithRedirect();
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: AppView,
    beforeEnter: () => {
        const { loginWithRedirect } = useAuth0();

        loginWithRedirect({authorizationParams: {
          screen_hint: "signup",
          mode: 'signUp',
        }})
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountManagement
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;