import { Node } from '@tiptap/core';
import { store } from '../../../../../services/store';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import {ReplaceStep, ReplaceAroundStep}from '@tiptap/pm/transform';

export default Node.create({
  name: 'rawInsert',
  content: 'text*',
  group: 'block',
  code: true,
  defining: true,
  marks: 'highlight',
  addAttributes() {
    return {
      class: {
        default: 'insert',
      },
      uid: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{
      tag: 'pre.insert',
      getAttrs: dom => ({
        class: dom.getAttribute('class'),
        'data-uid': dom.getAttribute('data-uid'),
      }),
    }];
  },
  renderHTML({ node }) {
    return ['pre', { class: node.attrs.class, 'data-uid': node.attrs.uid }, 0];
  },

  //update the inserts when the text updates
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handleRawInsertUpdates'),
        appendTransaction(transactions, _, newState) {
          if (!store.streamData.edits.inProgress) {
            transactions.forEach((transaction) => {
              transaction.steps.forEach((step) => {
                if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
                  let stepMap = step.getMap();
                  let affectedRanges = [];
                  stepMap.forEach((oldStart, oldEnd, newStart, newEnd) => {
                    affectedRanges.push({newStart, newEnd});
                  });

                  affectedRanges.forEach(({newStart, newEnd}) => {
                    if (newStart >= 0 && newEnd <= newState.doc.content.size) {
                      newState.doc.nodesBetween(newStart, newEnd, (node) => {
                        if (node.type.name === 'rawInsert') {
                          const editId = node.attrs.uid;
                          const newText = node.textContent;
                          const editTextIndex = store.state.editTexts.findIndex(editText => editText.id == editId);
                          if (editTextIndex !== -1 && store.state.editTexts[editTextIndex].insert !== newText) {
                            store.state.editTexts[editTextIndex].insert = newText;
                          }
                        }
                      });
                    }
                  });
                }
              });
            });
          }
        },
      }),
    ];
  }
});