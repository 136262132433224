import { store,refreshStreamData } from './store.js'
import { eventBus } from './eventBus';
import { streamConversationStep } from './apiService.js'

function removeBase64Images(markdownText) {
    // Regular expression pattern to match markdown image tags with base64 data
    const pattern = /!\[.*?\]\(data:image\/.*?;base64,.*?\)/g;
    // Replace the matched patterns with an empty string
    return markdownText.replace(pattern, "");
}


export async function streamResponseToStore(token) {
    if (store.state.activeItem && store.state.activeItem.type.startsWith('document')) {
        store.editorContent = store.state.activeItem.content;
    }
    refreshStreamData(store.editorContent === "");
    eventBus.emit('ResponseStream.Started');

    let document = null
    if (store.editorContent !== ""){
        document = {
            name: "untitled.md",
            content: removeBase64Images(store.editorContent)
        } 
    }

    const request =    { 
        model: store.state.model.model,
        messages: store.state.messageLog.filter(message => message.type === 'ai' || message.type === 'human'),
        document: document,
        context: {
            references: store.state.references.map( x => {
                return {
                    type: x.type,
                    name: x.name,
                    selected: x.selected,
                    content: removeBase64Images(x.content)
                };
            })
        }
    }

    let lastMessage = request.messages[request.messages.length - 1];

    if (lastMessage.requestType === 'question'){
        return await streamQuestionResponseToStore(token, request)
    }
    if (lastMessage.requestType === 'edit'){
        return await streamEditResponseToStore(token, request)
    }

    throw new Error("Tried to send message of unknown request type"); 
}


async function streamQuestionResponseToStore(token, request) {
    let questionResponse = store.streamData.questionResponse;

    try {
        const response = await streamConversationStep(token, request);
        
        await processQuestionResponseStream(response.body, questionResponse);

    } catch (error) {
        console.error("Failed to process request.", error);
        store.streamData.errors.push(error.message); // Store the error message
        throw error;
    }

    return { questionResponse };
}

async function processQuestionResponseStream(stream, questionResponse) {
    const reader = stream.getReader();
    let fullmessagebuffer = '';
    let buffer = '';
    let xml = true;

    async function read() {
        const { done, value } = await reader.read();
        let text_chunk = new TextDecoder().decode(value, { stream: true });
        fullmessagebuffer += text_chunk;
        buffer += text_chunk;
        
        if (store.streamData.cancel) {
            eventBus.emit('ResponseStream.Aborted');
            reader.cancel();
            return;
        }

        if (done) {
            store.streamData.rawResponseBody = fullmessagebuffer;
            questionResponse.inProgress = true;
            questionResponse.done = true;
            if (xml) {
                questionResponse.text = extractFirstCdataContent(buffer);
            }
            else {
                questionResponse.text = fullmessagebuffer;
            }
            eventBus.emit('ResponseStream.Ended');
            return;
        }

        if (buffer.includes('</Message>')) {
            eventBus.emit('QuestionResponseStream.Ended');
        }

        
        if (!questionResponse.inProgress && fullmessagebuffer.length > 15 && !fullmessagebuffer.includes('<Response>')) {
            eventBus.emit('QuestionResponseStream.Started');
            xml = false; // ai has not used XML structure for response
            questionResponse.inProgress = true;
        }

        if (questionResponse.inProgress) {
            if (xml) {
                questionResponse.text = extractFirstCdataContent(buffer);
            }
            else {
                questionResponse.text = fullmessagebuffer;
            }
        }

        if (!questionResponse.inProgress && buffer.includes('<Message>')) {
            eventBus.emit('QuestionResponseStream.Started');
            questionResponse.inProgress = true;
        }

        await read();
    }

        await read();

}

async function streamEditResponseToStore(token, request) {
    let preamble = store.streamData.preamble;
    let postamble = store.streamData.postamble;
    let edits = store.streamData.edits

    try {
        const response = await streamConversationStep(token, request);
        
        processEditResponseStream(response.body, preamble, edits, postamble);

    } catch (error) {
        console.error("Failed to process request.", error);
        store.streamData.errors.push(error.message); // Store the error message
        throw error;
    }

    return { preamble, edits, postamble };
}

async function processEditResponseStream(stream, preamble, edits, postamble) {
    const reader = stream.getReader();
    let fullmessagebuffer = '';
    let buffer = '';

    async function read() {
        const { done, value } = await reader.read();
        let text_chunk = new TextDecoder().decode(value, { stream: true });
        fullmessagebuffer += text_chunk;
        buffer += text_chunk;
        
        if (store.streamData.cancel) {
            eventBus.emit('ResponseStream.Aborted');
            reader.cancel();
            return;
        }

        if (done) {
            store.streamData.rawResponseBody = fullmessagebuffer;
            streamEditsToPointers(buffer,  preamble, edits, postamble);
            eventBus.emit('ResponseStream.Ended');
            return;
        }

        buffer = streamEditsToPointers(buffer, preamble, edits,postamble);

        await read();
    }

        await read();

}


function streamEditsToPointers(buffer, preamble, edits, postamble) {
    // Preamble ending
    if (buffer.includes('</PreAmble>')) {
        eventBus.emit('PreAmbleStream.Ended');
        const end = buffer.indexOf('</PreAmble>') + '</PreAmble>'.length;
        preamble.text = extractFirstCdataContent(buffer);
        preamble.done = true;
        preamble.inProgress = false;
        buffer = buffer.substring(end);
    }

    // Edits ending
    if (buffer.includes('</Edits>')) {
        eventBus.emit('EditStream.Ended');
        let end = buffer.indexOf('</Edits>') + '</Edits>'.length;
        buffer = streamToEditsPointer(buffer, edits);
        edits.inProgress = false
        edits.allDone = true;
        end = buffer.indexOf('</Edits>') + '</Edits>'.length;
        buffer = buffer.substring(end);
    }

    // Postamble ending
    if (buffer.includes('</PostAmble>')) {
        eventBus.emit('PostAmbleStream.Ended');
        const end = buffer.indexOf('</PostAmble>') + '</PostAmble>'.length;
        const postambleXML = buffer.substring(0, end);
        postamble.text = extractFirstCdataContent(postambleXML);
        postamble.done = true;
        postamble.inProgress = false;
        buffer = buffer.substring(end);
    }

    // Sections starting
    if (!preamble.inProgress && buffer.includes('<PreAmble>')) {
        eventBus.emit('PreAmbleStream.Started');
        preamble.inProgress = true;
    }
    else if (!edits.inProgress && buffer.includes('<Edits>')) {
        eventBus.emit('EditStream.Started');
        edits.inProgress = true;
    }
    else if (!postamble.inProgress && buffer.includes('<PostAmble>')) {
        eventBus.emit('PostAmbleStream.Started');
        postamble.inProgress = true;
    }

    // Sections in progress
    if (preamble.inProgress) {
        preamble.text = extractFirstCdataContent(buffer);
    } 
    else if (edits.inProgress) {
        buffer = streamToEditsPointer(buffer, edits);
    } 
    else if (postamble.inProgress) {
        postamble.text = extractFirstCdataContent(buffer);
    }


    return buffer;
}

function streamToEditsPointer(buffer, edits){
    const completeEditRegex = /<(Replace|Insert) (startLine="\d+" endLine="\d+"|line="\d+")>.*?<\/\1>/gs;
    
    let completeEdits = buffer.match(completeEditRegex);
    if (completeEdits !== null) {
        completeEdits = completeEdits.map(editXML => parseEditXML(editXML));
        edits.done = [...edits.done, ...completeEdits];// create new array to trigger vuejs refresh
    }
    
    buffer = buffer.substring(findLastEditIndex(buffer));

    let partialEdit = extractPartialEdit(buffer);

    edits.inProgressEdit =  partialEdit;

    return buffer;
}


function parseEditXML(editXML){
   
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(editXML, 'application/xml');
    const editElement = xmlDoc.documentElement;

    const type = editElement.tagName.toLowerCase();
    const startLine = Number(editElement.getAttribute('startLine'));
    const endLine = Number(editElement.getAttribute('endLine'));
    const line = Number(editElement.getAttribute('line'));
    const cdataSection = editElement.textContent.trim();

    const edit = {
        id: crypto.randomUUID(),
        done: true,
        type,
        text: cdataSection
    };

    if (type === 'replace') {
        edit.startLine = parseInt(startLine, 10);
        edit.endLine = parseInt(endLine, 10);
    } else { // type === 'insert'
        edit.line = parseInt(line, 10);
    }

    return edit;
}

function extractPartialEdit(buffer) {
    const tagRegex = /<(Replace|Insert)([^>]+)>/;
    const tagMatch = buffer.match(tagRegex);

    if (!tagMatch) {
        return {};
    }

    // Extract type and attributes
    const type = tagMatch[1].toLowerCase();
    const attributes = tagMatch[2].trim();
    const attrRegex = /(\w+)="(\d+)"/g;

    // Parse attributes
    let attrMatch;
    const edit = { 
        type,
        done: false, 
    };
    while ((attrMatch = attrRegex.exec(attributes)) !== null) {
        edit[attrMatch[1]] = parseInt(attrMatch[2], 10);
    }

    // Extract CDATA content
    edit.text = extractFirstCdataContent(buffer);

    return edit;
}

function findLastEditIndex(str) {
    const lastInsertIndex = str.lastIndexOf('</Insert>');
    const lastReplaceIndex = str.lastIndexOf('</Replace>');

    if (lastInsertIndex === -1 && lastReplaceIndex ===-1){
        return -1
    }

    return Math.max(lastInsertIndex+ '</Insert>'.length, lastReplaceIndex+ '</Replace>'.length);
}


function extractFirstCdataContent(buffer) {
    const cdataStart = buffer.indexOf('<![CDATA[');

    if (cdataStart === -1) {
        return; 
    }

    const contentStart = cdataStart + 9;  // Move past '<![CDATA['
    const contentEnd = buffer.indexOf(']]>', cdataStart);

    if (contentEnd === -1) {
        const partialCDataContent = buffer.substring(contentStart);
        const cleanedPartialCDataContent = partialCDataContent.replace(/\]+$/, '').trim(); // Remove trailing ']' characters and any whitespace
        return cleanedPartialCDataContent;
    } else {
        return buffer.substring(contentStart, contentEnd).trim();
    }
}