<template>
    <span 
      class="file-trigger" 
      @click="toggleMenu"
      aria-haspopup="true" 
      aria-controls="overlay_tmenu"
      tabindex="0" 
    >Session</span>
    <TieredMenu ref="menu" :model="menuItems" popup />


</template>

<script>
import { eventBus } from '../services/eventBus';
import { store } from '../services/store';


export default {
  name: 'SessionFileMenu',
  data() {
    return {
      menuItems: [
      { label: 'New', icon: 'pi pi-fw pi-plus', command: this.newFile},
      { label: 'Open', icon: 'pi pi-fw pi-folder-open', command: this.openFile },
      { label: 'Save', icon: 'pi pi-fw pi-save', command: this.saveFile },
      { label: 'Save As...', icon: 'pi pi-fw pi-copy', command: this.saveFileAs }
      ]
    }
  },
  mounted() {
window.addEventListener('keydown', this.handleGlobalSaveShortcut);
  },
beforeUnmount() {
  window.removeEventListener('keydown', this.handleGlobalSaveShortcut);
},
    methods: {
      toggleMenu() {
      this.$refs.menu.toggle(event);
    },
      async newFile() {
            this.fileHandle = null;
            store.state.isNewSession = true;
            store.state.sessionFileName = '';
            store.state.sessionId = crypto.randomUUID();
            store.state.messageLog = []
            store.state.references = []
            store.state.activeItem = {id: '', content: ''}
            store.state.documents = [{
            id: 0,
            type: 'document-custom',
            name:  'Untitled',
            content: '',
            selected: true,
            }]
            // store.editorContent = '';
            eventBus.emit('ActiveItem.Set', ''); 
      },
      async openFile() {
        if ('showOpenFilePicker' in window) {
            const [fileHandle] = await window.showOpenFilePicker({
              types: [{
                description: 'Mynt file',
                accept: {'text/text': ['.mynt']},
              }],
              multiple: false
            });
            if (fileHandle) {
              this.fileHandle = fileHandle;
              const file = await fileHandle.getFile();
              const content = await file.text();
              const state = JSON.parse(content);

              if (state.editorContent) {
                const id = crypto.randomUUID();
                const newDocument = {
                  id: id,
                  type: 'document-custom',
                  name: id, 
                  content: state.editorContent,
                };

                // Add newDocument to the documents array in the state
                // Ensure documents array exists, or initialize it
                if (!state.documents) {
                  state.documents = [];
                }
                state.documents.push(newDocument);

                // Set store.editorContent to null
                state.editorContent = null;
              }

              store.state = { ...store.state, ...state };
        } else {
          this.$refs.fileInput.click();
        }
      }
  },
  async saveFile() {
      if (this.fileHandle == null) {
        return this.saveFileAs();
      }
      if ('showSaveFilePicker' in window) {
        try {
          const writableStream = await this.fileHandle.createWritable();
          const state = JSON.stringify(store.state);
          await writableStream.write(state);
          await writableStream.close();
          eventBus.emit('Toast.Show', { message: 'File Saved', type: 'success' });
        } catch (error) {
          eventBus.emit('Toast.Show', { message: 'Error Saving File', type: 'error' });
        }
      } else {
        return this.saveFileAs();
      }
  },
  async saveFileAs() {
      if ('showSaveFilePicker' in window) {
        const options = {
          suggestedName: store.state.sessionFileName || 'untitled',
          types: [{
            description: 'Mynt file',
            accept: {'text/text': ['.mynt']},
          }],
        };

        try {
          const handle = await window.showSaveFilePicker(options);
          store.state.sessionFileName = handle.name;
          const writableStream = await handle.createWritable();
          const state = JSON.stringify(store.state);
          await writableStream.write(state);
          await writableStream.close();
          this.fileHandle = handle;
        } catch (error) {
          if (error.name === 'AbortError') {
            // User cancelled the save as dialog
            return;
          }
          eventBus.emit('Toast.Show', { message: 'Error Saving File', type: 'error' });
          throw error; // Rethrow the error if it's not an AbortError
        }
      } else {
          this.downloadFile(store.state.sessionFileName || 'untitled.mynt');
      }
  },
  downloadFile(fileName) {
    const state = JSON.stringify(store.state);
    const blob = new Blob([state], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  },
  handleGlobalSaveShortcut(event) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault(); 
      this.saveFile();
    }
  },
    }
  }
</script>

<style scoped>


</style>