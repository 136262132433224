import { getDocument,GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.js";

GlobalWorkerOptions.workerSrc = pdfjsWorker;

export async function convertPdfToText(file) {
    try {
      // Create a Blob URL if you have a File object
      const fileURL = URL.createObjectURL(file);
      const pdf = await getDocument({ url: fileURL }).promise;
      let text = '';
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const content = await page.getTextContent();
        text += content.items.map(item => item.str).join(' ') + '\n';
      }
      URL.revokeObjectURL(fileURL); // Clean up the Blob URL
      return text;
    } catch (error) {
      console.error('Error converting PDF to text:', error);
      throw error;
    }
  }
  
