<template>
  <div class="account-details">
    <h2>{{ store.user.name }}</h2>
    <p><strong>AI Credits:</strong> {{ this.toDollars(store.account.balance_dollars) }}</p>
    <!-- <p><strong>Status:</strong> {{ store.account.status == "Trial"? "Free" : store.account.status }}</p> -->
    <button id="paddle-checkout-button" @click="openPaddleCheckout">Buy More Credits</button>
  </div>
</template>

<script>
  /* global Paddle */
  import { store } from '../services/store';
  function openPaddleCheckout() {
      Paddle.Checkout.open({
        settings: {
          displayMode: "overlay",
        },
        //items: [{priceId: 'pri_01hspnvve1m8052a89s999hk4y', quantity: 1}],
        items: [{priceId: 'pri_01hsxvw76z82kb6ht777s9cnnn', quantity: 1}],
        customer: {
          email: store.user.email,
        },
        customData: {
          user_id: store.user.sub
        },
      });
  }

  export default {
    data() {
      return {
        store
      };
    },

    methods: {
      openPaddleCheckout,
      toDollars(number) {
        if (number === undefined || typeof number !== 'number') {
          return '';
        }
        return '$' + number.toFixed(2);
      }
    }
  }
</script>

<style scoped>
.account-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#paddle-checkout-button {
    padding: 10px 20px;
    margin: 20px;
    border: 0.5px solid #d2d5da;
    border-radius: 5px;
    background-color:#3c7e6dd9;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#paddle-checkout-button:hover {
  background-color:rgb(4, 111, 81);
}
</style>