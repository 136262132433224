<template>
    <PvDialog v-model:visible="store.state.isNewSession" :modal="true" style="width: 600px">
      <PvStepper v-model:activeStep="activeIndex">
        <StepperPanel header="Welcome">
          <template #content>
            <p>
              What do you want to do?
            </p>
            <div>
              <div class="field-radiobutton">
                <RadioButton id="option1" value="1" v-model="selectedOption"  />
                <label class="rad-label"  for="option1">Discuss existing content</label>
              </div>
              <div class="field-radiobutton">
                <RadioButton id="option2" value="2" v-model="selectedOption"/>
                <label class="rad-label"  for="option2">Write new content</label>
              </div>
              <div class="field-radiobutton">
                <RadioButton id="option3" value="3" v-model="selectedOption"  />
                <label class="rad-label" for="option3">Both - Write from existing content</label>
              </div>
              <div style="display: flex; justify-content: end;">
              <PvButton label="Next" icon="pi pi-arrow-right" iconPos="right"   @click="startFlow"/>
            </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="References">
          <template #content>
            <div class="items-section">
              <h3 style="font-size:20px; font-weight: 500; margin: 5px 0 ;">Add read-only references for the AI</h3>
            <div style="width: 300px;">
            <ReferenceItem
              v-for="(reference, index) in store.state.references"
              :key="reference.id"
              :index="index"
            />
          </div>
            <AddReferenceMenu />
          </div>
            <div style="display: flex; justify-content: space-between;">
            <PvButton label="Back" severity="secondary" icon="pi pi-arrow-left" @click="() => activeIndex = 0" />
            <PvButton v-if="selectedOption === '1'" label="Start" icon="pi pi-arrow-up-right" iconPos="right"  @click="closeDialogue"/>
            <PvButton v-if="selectedOption === '3'" label="Next" icon="pi pi-arrow-right" iconPos="right"   @click="() => activeIndex = 2"/>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Content">
          <template #content>
            <div class="items-section">
              <h3 style="font-size:20px; font-weight: 500; margin: 5px 0 ;">Generate and Edit Documents With AI</h3>
              <div style="width: 300px;">
                <DocumentItem
                  v-for="(documents, index) in store.state.documents"
                  :key="documents.id"
                  :index="index"
                />
              </div>
            <AddDocumentMenu />
            </div>
            <div style="display: flex; justify-content: space-between;">
            <PvButton v-if="selectedOption === '2'"  label="Back" severity="secondary" icon="pi pi-arrow-left" @click="() => activeIndex = 0" />
            <PvButton v-if="selectedOption === '3'"  label="Back" severity="secondary" icon="pi pi-arrow-left" @click="() => activeIndex = 1" />
            <PvButton label="Start" icon="pi pi-arrow-up-right" iconPos="right"   @click="closeDialogue"/>
            </div>
          </template>
        </StepperPanel>
      </PvStepper>
    </PvDialog>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { store } from '../services/store.js';
  import ReferenceItem from './ReferencePanel/ReferenceItem.vue';
  import AddReferenceMenu from './ReferencePanel/AddReferenceMenu.vue';
  import DocumentItem from './ReferencePanel/DocumentItem.vue';
  import AddDocumentMenu from './ReferencePanel/AddDocumentMenu.vue';
  
  export default {
    components: {
      ReferenceItem,
      AddReferenceMenu,
      DocumentItem,
      AddDocumentMenu
    },
    setup() {
      const activeIndex = ref(0);
      const selectedOption = ref(null);
  

      const startFlow = () => {
        if (selectedOption.value === "1" || selectedOption.value === "3") {
            activeIndex.value = 1;
        }
        if (selectedOption.value === "2") {
            activeIndex.value = 2;
        }
      }

      const closeDialogue = () => {
        store.state.isNewSession = false;
        selectedOption.value = null;
        activeIndex.value = 0;
      }
  
      return {
        activeIndex,
        selectedOption,
        startFlow,
        closeDialogue
      };
    },
    mounted () {
        if (store.state.messageLog.length == 0 && store.state.references.length == 0 && store.state.documents.length == 0) {
            store.state.isNewSession = true;
        }
    },
    data() {
    return {
      store
    }
  },
  };
  </script>
  
  <style scoped>

  .items-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .field-radiobutton {
    margin-bottom: 1rem;
  }

  .rad-label {
    margin-left: 0.5rem;
  }
  </style>