import { reactive, computed} from 'vue'
import { eventBus } from './eventBus';

export const store = reactive({
  user: {},
  account: {},
  isMobile: false,
  currentAppView: 'both',
  editorContent: '',
  editor:null,
  aiInProgress: false,
  state: {
    model: {},
    isNewSession: false,
    sessionId: '',
    sessionFileName: '' ,
    messageLog: [],
    activeItem: null,
    documents: [{
      id: 0,
      type: 'document-custom',
      name:  'Untitled',
      content: '',
      selected: true,
      }],
    references: [], // {id: c:/file/path, plainText: 'document content', original: binary-of-original? , type: input, file, }
    editTexts: [],
  },
  request: {
    id: '',
    timeStamp: '',
    body: '' ,
    type: '',
    endpoint: '',
    abortController: ''
  },
  streamData: {
    rawResponseBody: '',
    questionResponse: { inProgress: false, done: false, text: '' },
    preamble: { inProgress: false, done: false, text: '' },
    postamble: { inProgress: false, done: false, text: '' },
    edits: { 
      done: [],
      inProgressEdit:{},
      inProgress: false,
      allDone: false
    },
    errors: [],
    cancel:false,
  }, 
  toast: {
    message: '',
    type: 'info',
    visible: false
  }
});

export function refreshStreamData(isInitial = false) {
  store.streamData.rawResponseBody = '';
  store.streamData.questionResponse = { inProgress: false, done: false, text: '' };
  store.streamData.preamble = { inProgress: false, done: false, text: '' };
  store.streamData.postamble = { inProgress: false, done: false, text: '' };
  store.streamData.edits.done = [];
  store.streamData.edits.inProgressEdit = {};
  store.streamData.edits.inProgress = false;
  store.streamData.edits.allDone = false;
  store.streamData.edits.isInitial = isInitial;
  store.streamData.errors = []; 
  store.streamData.cancel =false;
}

export function setActiveItem(id) {
  if (store.aiInProgress)
    return;
  // If there is an existing activeItem, find it in documents or references and update it
  if (store.state.activeItem) {
    const activeId = store.state.activeItem.id;

    store.state.activeItem.isActive = false;
    const foundInDocumentsIndex = store.state.documents.findIndex(doc => doc.id === activeId);
    const foundInReferencesIndex = store.state.references.findIndex(ref => ref.id === activeId);

    if (foundInDocumentsIndex !== -1) {
      // Update the document with the contents of activeItem
      store.state.documents[foundInDocumentsIndex] = { ...store.state.activeItem };
    } else if (foundInReferencesIndex !== -1) {
      // Update the reference with the contents of activeItem
      store.state.references[foundInReferencesIndex] = { ...store.state.activeItem };
    }
  }

  // Now, find the new activeItem by id in documents or references
  const newActiveInDocuments = store.state.documents.find(doc => doc.id === id);
  const newActiveInReferences = store.state.references.find(ref => ref.id === id);

  // Update activeItem to the new item
  if (newActiveInDocuments) {
    newActiveInDocuments.isActive = true;
    store.state.activeItem = { ...newActiveInDocuments };
  } else if (newActiveInReferences) {
    newActiveInReferences.isActive = true;
    store.state.activeItem = { ...newActiveInReferences };
  } else {
    store.state.activeItem = null;
  }

  eventBus.emit('ActiveItem.Set');
}

export const wordCount = computed(() => {
  let count = 0;

  if (store.state.activeItem && store.state.activeItem.type && store.state.activeItem.type.startsWith('document')){
    count += store.state.activeItem.content.trim().split(/\s+/).length;
  }

  count += store.state.references.reduce( (acc, reference) => acc + reference.content.trim().split(/\s+/).length, 0);
  return count;
});

export function refreshActiveItemData() {
  if (store.state.activeItem) {
    setActiveItem(store.state.activeItem.id);
  }
}