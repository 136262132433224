<template>
  <div class="chat-panel">
    <div v-if="store.state.messageLog.length > 0" class="clear-chat-wrap" @click="showConfirmationModal = true">
    <button v-tooltip.right="{ value: 'Clear all', showDelay: 300}" class="clear-chat">
      &times;
    </button>
  </div>
    <div class="message-log" ref="messageLogContainer">
      <div v-for="(message, index) in store.state.messageLog" :key="index" :class="['message-wrapper message-wrapper-' +  message.type]"  >
        <div v-if="message.type === 'edit-generating'" class="loading-container message message-service">
          <span class="spinner"></span> Generating...
        </div>
        <div :class="['message message-' + message.type]">
          {{ message.message }}
        </div>
      </div>
    </div>
    <MessageInput :isSending="store.aiInProgress" @sendMessage="sendMessage"></MessageInput>
    <ConfirmationModal
      :isVisible="showConfirmationModal"
      title="Clear Chat History"
      message="Are you sure you want to continue?"
      @confirm="confirmClearChat"
      @cancel="cancelClearChat"
      />
  </div>
</template>



<script>
import { store } from '../../services/store.js';
import { streamResponseToStore } from '../../services/responseStreamService.js'
import { sendRequestLog } from '../../services/apiService.js'
import MessageInput from './MessageInput.vue';
import ConfirmationModal from './../ConfirmationModal.vue';
import { eventBus } from '../../services/eventBus';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'ChatPanel',
  components: {
    MessageInput,
    ConfirmationModal
  },
  setup() {
    const { getAccessTokenSilently, logout } = useAuth0();
    return { getAccessTokenSilently, logout };
  },
  data() {
    return {
      store,
      isAiEditing: false,
      showConfirmationModal: false
    };
  },
  mounted() {
    //this.addTestMessages(); // Add test messages for development/testing
    eventBus.on('QuestionResponseStream.Started', this.questionResponseStart);
    eventBus.on('PreAmbleStream.Started', this.preAmbleStart);
    eventBus.on('EditStream.Started', this.editStart);
    eventBus.on('EditStream.Ended', this.editEnd);
    eventBus.on('PostAmbleStream.Started', this.postAmbleStart);
    eventBus.on('ResponseStream.Ended', this.responseEnd);
    eventBus.on('ResponseStream.Aborted', this.responseAborted);
  },
  beforeUnmount() {
    eventBus.off('QuestionResponseStream.Started', this.questionResponseStart);
    eventBus.off('PreAmbleStream.Started', this.preAmbleStart);
    eventBus.off('EditStream.Started', this.editStart);
    eventBus.off('EditStream.Ended', this.editEnd);
    eventBus.off('PostAmbleStream.Started', this.postAmbleStart);
    eventBus.off('ResponseStream.Ended', this.responseEnd);
    eventBus.off('ResponseStream.Aborted', this.responseAborted);
  },
  methods: {
    async sendMessage({ message, requestType }) {
      if (message.trim()) {
        this.addNewMessage({ message: message, type: 'human', requestType: requestType});
        try {
          let token = ''
          try {
            token = await this.getAccessTokenSilently();
          }
          catch (error) {
            await this.logout();
          }

          if (store.account.balance_dollars <= 0 && !store.state.model.free) {
            eventBus.emit('Toast.Show', { message: 'Not Enough Credit to use this model', type: 'error' });
            return;
          }

          store.aiInProgress = true;

          await streamResponseToStore(token);
        } catch (error) {
          console.error('Error sending message to AI:', error);
          store.aiInProgress = false;
          this.isAiEditing = false;
          this.responseAborted();
        }
        
      }
    },
    async streamAmbleToLastMessage(amble, timeoutToStarting) {
      // Timeout function
      const timeout = new Promise((resolve, reject) => {
          setTimeout(() => {
              store.aiInProgress = false;
              reject(new Error('Streaming timed out'));
          }, timeoutToStarting); 
      });

      // Function to start and process streaming
      const startStreaming = new Promise((resolve) => {
          const checkAmbleStart = setInterval(() => {
              if (amble.inProgress) {
                  clearInterval(checkAmbleStart);
                  const updateInterval = setInterval(() => {
                    store.state.messageLog[store.state.messageLog.length - 1].message = amble.text;
                    this.scrollToBottom();

                      if (amble.done) {
                          clearInterval(updateInterval);
                          resolve();
                      }
                  }, 100);
              }
          }, 100);
      });
      return Promise.race([startStreaming, timeout]);
    },
    questionResponseStart(){
      this.addNewMessage({ message: '', type: 'ai' });
      this.streamAmbleToLastMessage(store.streamData.questionResponse, 60000);
    },
    preAmbleStart(){
      this.addNewMessage({ message: '', type: 'ai' });
      this.streamAmbleToLastMessage(store.streamData.preamble, 60000);
    },
    editStart(){
      this.isAiEditing = true;
      this.addNewMessage({ type: 'edit-generating' });
    },
    editEnd(){
      this.isAiEditing = false;
      store.state.messageLog.pop();
      if (store.streamData.edits.done.length > 1) {
        this.addNewMessage({ message: 'Edits Applied', type: 'service' });
      }
      else {
        this.addNewMessage({ message: 'Edit Applied', type: 'service' });
      }
    },
    postAmbleStart(){
      this.addNewMessage({ message: '', type: 'ai' });
      this.streamAmbleToLastMessage(store.streamData.postamble, 60000);
    },
    async responseEnd() {
      store.aiInProgress = false;
      const token = await this.getAccessTokenSilently();

      const requestLog = {
        'requestID': store.request.id,
        'sessionID': store.state.sessionId,
        'timestamp': store.request.timeStamp,
        'endpoint': store.request.endpoint,
        'requestType': store.request.type,
        'rawRequestBody': store.request.body,
        'rawResponseBody': store.streamData.rawResponseBody,
        'documentAfterEdits': store.editorContent,
        'errors': store.streamData.errors, 
        'frontendVersion': '0.8.1',
      };

      await sendRequestLog(token, requestLog)
      
    },
    responseAborted() {
      store.aiInProgress = false;
      this.isAiEditing = false;
      this.store.state.messageLog = this.store.state.messageLog.filter(message => message.type !== 'edit-generating');
    },
    scrollToBottom() {
      this.$nextTick(() => {
          const container = this.$refs.messageLogContainer;
          container.scrollTop = container.scrollHeight;
      });
    },
    addNewMessage(message) {
      store.state.messageLog.push(message);
      this.scrollToBottom();
    },
    confirmClearChat() {
        store.state.messageLog = [];
        this.showConfirmationModal = false;
      },
      cancelClearChat() {
        this.showConfirmationModal = false;
      },
    //   addTestMessages() {
    //   if (process.env.NODE_ENV !== 'production') {
    //     store.state.messageLog.push({ message: 'Hello AI, can you help me write a report?', type: 'human' });
    //     store.state.messageLog.push({ message: 'Sure, what topic are you interested in?', type: 'ai' });
    //     store.state.messageLog.push({ message: 'I need a report on the impact of climate change.', type: 'human' });
    //     store.state.messageLog.push({ message: 'Understood. I will generate a draft for you.', type: 'ai' });
    //   }
    // },
  }
}
</script>

<style scoped>
.chat-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

.message-wrapper {
  display:flex;
}

.message-wrapper-human {
  justify-content: right;
}

.message-wrapper-ai {
  justify-content: left;
}

.message-wrapper-service {
  justify-content: center;
}

.message {
  direction:ltr;
  width:fit-content;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 3px 0;
  white-space: pre-wrap;
}

.message-log {
  position: relative;
  direction: rtl; 
  flex-grow: 1;
  overflow-y: scroll;
  margin: 5px -8px;
  padding: 5px;

}

.clear-chat-wrap {
  position: relative;
  top: 4px;
  left: 4px;
  width: fit-content;
  height: 0px;
  z-index: 10;
}

.clear-chat {
  background: #fffffff0;
  border: none;
  border-radius: 8px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

.clear-chat:hover {
  color: #333;
}


.message-human {
  background-color: #48cd938a;
  text-align: right;
  margin-left: 35px;
}

.message-ai {
  background-color: #e5e5ea;
  text-align: left;
  margin-right: 35px;
}
.message-service {
  color: #3c7e6d8c;
  text-align: left;
  margin-right: 35px;
}


.ai-response {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loading-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>../ReferencePanel/ReferencesPanel.vue/index.js