<template>
  <div >
    <HeaderBar />
    <AuthPrompt v-if="!isAuthenticated" />
    <router-view  v-if="isAuthenticated" />
    <OpeningDialogue  v-if="isAuthenticated" @after-hide="() => this.store.state.isNewSession = false"/>
    <CreditModal
        v-if="showCreditModal"
        :message="creditModalMessage"
        :action="creditModalAction"
        :buttonText="creditModalButtonText"
      />
      <ToastPopup/>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar.vue';
import AuthPrompt from './components/AuthPrompt.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { eventBus } from './services/eventBus';
import { store } from './services/store';
import CreditModal from './components/CreditModal.vue';
import ToastPopup from './components/ToastPopup.vue';
import OpeningDialogue from './components/OpeningDialogue.vue';
import { getAccountDetails, requestMoreCredits, getModels } from './services/apiService.js'

export default {
  name: 'App',
  components: {
    HeaderBar,
    AuthPrompt,
    CreditModal,
    OpeningDialogue,
    ToastPopup
  },
  setup() {
    const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
    return { user, isAuthenticated, getAccessTokenSilently, logout };
  },
  data() {
    return {
      store,
      showCreditModal: false,
      creditModalMessage: '',
      creditModalAction: null,
      creditModalButtonText: 'Request More Credits',
      // Added to control repeated account status checks
      checkingAccountStatus: false,
    };
  },
  async created() {
    this.checkAccountStatus();
    eventBus.on('ResponseStream.Ended', this.checkAccountStatus);
  },

  beforeUnmount() {
    eventBus.off('ResponseStream.Ended', this.checkAccountStatus);
  },
  methods: {
    setUser() {
      store.user = this.user;
    },
    async checkAccountStatus() {
      // Prevent repeated checks
      if (this.checkingAccountStatus) {
        return;
      }

      this.checkingAccountStatus = true;

      try {
        const token = await this.getAccessTokenSilently();

        if (!this.isAuthenticated) {
          return;
        } 

        this.setUser();

        const accountDetails = await getAccountDetails(token);

        await this.handleAccountDetails(accountDetails, token);
        
      } catch (error) {
        console.error('Error fetching account details or getting access token:', error);
      } finally {
        this.checkingAccountStatus = false;
      }
    },
    async handleAccountDetails(accountDetails, token) {
      if (accountDetails.status === 'WaitingList') {
        this.showCreditModal = true;
        if (accountDetails.status === 'WaitingList') {
          this.creditModalMessage = 'The Mynt free trial is currently oversubscribed and you have been added to the waiting list. We will contact you very soon.';
        }
        // } else if (accountDetails.status === 'RequestedMore') {
        //   this.creditModalMessage = 'Thank you for trying Mynt. Our team will reach out soon.';
        // } else {
        //   this.creditModalMessage = 'You are out of free credit! Click here to request more.';
        //   this.creditModalAction = this.requestCreds;
        // }
      }

      store.account = accountDetails;

      if (Object.keys(store.state.model).length === 0) {
        const models = await getModels(token);
      if (store.account.balance_dollars > 0) {
        store.state.model = models[1];
      }
      else {
        store.state.model = models[0];
      }
      }

    },
    async requestCreds() {
      try {
        const token = await this.getAccessTokenSilently();
        await requestMoreCredits(token);
        await this.checkAccountStatus();
      } catch (error) {
        console.error('Error requesting more credits:', error);
      }
    },
  }
}
</script>

<style>
body {
  margin: 0;
}

/* Scrollbars */

/* Styles the scrollbar track */
::-webkit-scrollbar {
    width: 6px; /* Slimmer width for a more minimalist look */
    background-color: transparent; /* Transparent track, emphasizing minimalism */
}

/* Styles the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #b0b0b0; /* Soft, neutral color for the thumb */
    border-radius: 3px; /* Slightly rounded corners for a modern look */
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
}

/* Enhances the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #808080; /* Darker shade on hover for better visibility and interactivity */
}

/* Tooltips */

[role="tooltip"] {
  font-size: small;
}

</style>
