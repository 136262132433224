<template>
  <div>
    <PvButton icon="pi pi-fw pi-plus" aria-label="Add Document" @click="toggleMenu" class="add-reference-trigger" />    
    <TieredMenu ref="menu" :model="menuItems" popup />
    <PvDialog v-model:visible="showDocumentDialog" header="Add Document" :style="{ width: '50vw' }">
    <div>
      <div class="field">
        <label for="title">Title</label>
        <InputText type="text" id="title" v-model="documentTitle" />
      </div>
      <div class="field">
        <label for="description">Description</label>
        <TextArea id="description" v-model="documentDescription"></TextArea>
      </div>
      <PvButton label="Add" @click="addDocumentWithDetails" />
    </div>
    </PvDialog>
    <PvDialog v-model:visible="showImportDocumentDialog" header="Add Document" :style="{ width: '50vw' }">
      <FileUpload mode="basic" :multiple="true" :maxFileSize="10000000" chooseLabel="Import Existing" customUpload auto @uploader="loadFromFile" :accept="acceptFileTypes" />
    </PvDialog>
  </div>
</template>

<script>
import { store, setActiveItem } from '../../services/store.js';
import { extractPlainTextFromFile,allFileTypes } from '../../services/fileService';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'AddDocumentMenu',
  setup() {
  const { getAccessTokenSilently } = useAuth0();
  return { getAccessTokenSilently };
},
  data() {
    return {
      menuItems: [ 
        { label: 'New Document', icon: 'pi pi-fw pi-file', command: () => { this.showDocumentDialog = true;} },
         { label: 'Import Document', icon: 'pi pi-fw pi-file-import',command: () => { this.showImportDocumentDialog = true;} },
        { label: 'Code (Coming Soon)', icon: 'pi pi-fw pi-check-square', command: () => {} },
        // { label: 'User Stories (Coming Soon)', icon: 'pi pi-fw pi-check-square', command: () => {} },
        { label: 'Structured Output (Coming Soon)', icon: 'pi pi-fw pi-wrench', command: () => {} },
      ],
      showDocumentDialog: false,
      showImportDocumentDialog: false,
      documentTitle: '',
      documentDescription: '',
      acceptFileTypes: allFileTypes.join(',')
    }
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    addDocument() {
      this.showDocumentDialog = true;
    },
    addDocumentWithDetails() {
      const id = crypto.randomUUID();
      const newDocument = {
            id: id,
            description: this.documentDescription,
            type: 'document-custom',
            name: this.documentTitle,
            content: '',
      };
          // Close the document dialog only if it's open
          if (this.showDocumentDialog) {
            this.showDocumentDialog = false;
          }
          this.documentTitle = '';
          this.documentDescription = '';
          store.state.documents.push(newDocument);

          setActiveItem(id);
    },
    async loadFromFile(event) {
      for (const file of event.files) {
        try {
          const text = await extractPlainTextFromFile(file, this.getAccessTokenSilently);
          const id = crypto.randomUUID();
          const newDocument = {
            id: id,
            type: 'document-custom',
            name: file.name,
            content: text,
            selected: true,
          };
          store.state.documents.push(newDocument);
          setActiveItem(id);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
      // Close the document dialog only if it's open
      if (this.showImportDocumentDialog) {
        this.showImportDocumentDialog = false;
      }
    }
  
  }
};
</script>

<style scoped>
.add-reference-trigger {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.field {
  margin-bottom: 1rem;
}

.field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.field input[type="text"],
.field textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #3c7e6d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.add-button:hover {
  background-color: #2e6959;
}
.p-inputtext:enabled:focus {
  box-shadow: none;
}
</style>