import { createApp } from 'vue';
import App from './App.vue';
import { createAuth0 } from '@auth0/auth0-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencilAlt
  , faComment
  , faFile
  , faFileAlt
  , faFolderOpen
  , faSave
  , faCopy
  , faShareAlt
  , faFileImport
  , faFileExport
  , faHeader
  , faBold
  , faItalic
  , faListOl
  , faListUl
  , faCode
  , faQuoteRight
  , faUndo
  , faRedo
  , faFileCode
  , faTrashAlt
  , faUser
  , faTable
  , faPaperPlane
  , faMagicWandSparkles
  , faEyeSlash
  , faEye
} from '@fortawesome/free-solid-svg-icons';
import { store } from './services/store.js'
import debounce from 'lodash/debounce';
import router from './router';
import paddlePlugin from './plugins/paddle';
import 'primeicons/primeicons.css'

library.add(
  faPencilAlt
  , faEye
  , faComment
  , faFile
  , faFileAlt
  , faFolderOpen
  , faSave
  , faCopy
  , faShareAlt
  , faFileImport
  , faFileExport
  , faHeader
  , faBold
  , faItalic
  , faCode
  , faListOl
  , faListUl
  , faQuoteRight
  , faUndo
  , faRedo
  , faFileCode
  , faTrashAlt
  , faUser
  , faTable
  , faPaperPlane
  , faMagicWandSparkles
  , faEyeSlash
  , faEye
  );
  import TextArea from "primevue/textarea"
  import PrimeVue from 'primevue/config';
  import 'primevue/resources/themes/lara-light-green/theme.css';

  import Splitter from 'primevue/splitter';
  import SplitterPanel from 'primevue/splitterpanel';
  import Panel from 'primevue/panel';
  import Tooltip from 'primevue/tooltip';
  import Tag from 'primevue/tag';
  // import Toolbar from 'primevue/toolbar';
  import Divider from 'primevue/divider';
    import Menu from 'primevue/menu';
  import TieredMenu from 'primevue/tieredmenu';
  import Button from 'primevue/button';
  // import Accordion from 'primevue/accordion';
  // import AccordionTab from 'primevue/accordiontab';
  import Dialog from 'primevue/dialog';
  import FileUpload from 'primevue/fileupload';
  // import InputSwitch from 'primevue/inputswitch';
  // import Checkbox from 'primevue/checkbox';
  import ToggleButton from 'primevue/togglebutton';
import InputText from 'primevue/inputtext';
import Inplace from 'primevue/inplace';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import RadioButton from 'primevue/radiobutton';

import VueGtag from 'vue-gtag';


const app = createApp(App);

app.component('FontAwesomeIcon', FontAwesomeIcon);


app.use(PrimeVue, {
  // unstyled: true
});

app.component('TextArea', TextArea);
app.component('SplitterMain', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('BasicPanel', Panel);
app.directive('Tooltip', Tooltip);
app.component('PvDivider', Divider);
app.component('TieredMenu', TieredMenu);
app.component('PvMenu', Menu);
app.component('PvButton', Button);
// app.component('PvAccordion', Accordion);
// app.component('PvAccordionTab', AccordionTab);
app.component('PvDialog', Dialog);
app.component('FileUpload', FileUpload);
app.component('ToggleButton', ToggleButton);
app.component('InputText', InputText);
app.component('PvInplace', Inplace);
app.component('PvStepper', Stepper);
app.component('StepperPanel', StepperPanel);
app.component('RadioButton', RadioButton);
app.component('PvTag', Tag);


app.use(VueGtag, {
  config: { id: 'G-XR431K6B62' }, // Replace with your actual tracking ID
  appName: 'Mynt App', // Optional: define your app name
  pageTrackerScreenviewEnabled: true // Optional: enable screen view tracking
}, router);  // Make sure to pass the router instance if you want to automatically track page views


app.use(router); 

app.use(
  createAuth0({
    domain: "mynt.uk.auth0.com",
    clientId: "JEttx5tklapHpItlDFgnXpArcwi0aFAK",
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://www.document-generator.com/api",
      scope: "access_all email profile"
    },
    cacheLocation: 'localstorage',
    useRefreshTokens: true
  })
);

// app.use(
//   createAuth0({
//     domain: "mynt-dev.uk.auth0.com",
//     clientId: "oSklB7IaLj4gKeGGl8DJOsxlYrjl0hqC",
//     authorizationParams: {
//       redirect_uri: window.location.origin,
//       audience: "https://www.document-generator.com/api",
//       scope: "access_all email profile"
//     },
//     cacheLocation: 'localstorage',
//     useRefreshTokens: true
//   })
// );

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');


//app.use(paddlePlugin, { environment: 'sandbox', token: 'test_08af256fc781ac01324cef974dd' });
app.use(paddlePlugin, { environment: 'production', token: 'live_02b8bf1113360cdc98d48b22892' });



// Load state from local storage when the app starts
const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem('myntStateV1');
  if (savedState) {
   Object.assign(store.state, JSON.parse(savedState));
  }
};

loadStateFromLocalStorage();

const saveStateToLocalStorage = () => {
  localStorage.setItem('myntStateV1', JSON.stringify(store.state));
};

const debouncedSaveState = debounce(saveStateToLocalStorage, 1000); 

app.mixin({
  watch: {
    'store.state': {
      handler: debouncedSaveState,
      deep: true
    }
  }
});