<template>
    <node-view-wrapper class="edit-block" :data-uid="node.attrs.uid">
      <button class="delete-button" @click="deleteEdit(node.attrs.uid)">
        <FontAwesomeIcon :icon="['fas', 'trash-alt']" />
      </button>
      <!-- <DeleteSection :uid="node.attrs.uid" /> -->
      <!-- <button @click="undoDelete(0)" class="undo-btn">Undo Delete</button> -->
      <node-view-content/>
      <div class="controls">
        <button @click="actionEdit(node.attrs.uid, true)">Accept</button>
        <button @click="actionEdit(node.attrs.uid, false)">Undo</button>
      </div>
    </node-view-wrapper>
  </template>
  
  <script>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  import { store } from '../../../../services/store.js'
  import { eventBus } from '../../../../services/eventBus.js'
  import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3';
  //  import DeleteSection from './DeleteSection.vue'; 
  
  export default {
    components: {
      NodeViewWrapper,
      NodeViewContent,
      FontAwesomeIcon
      //  DeleteSection
    },
  
    props: {
      ...nodeViewProps,
      node: {
        type: Object,
        required: true,
      },
    },

    methods: { 
      actionEdit(editId, accept) {

        const editBlockPattern = new RegExp(`::: edit \\[${editId}\\][\\s\\S]*?:::`, 'g');
        let document = store.state.activeItem.content;
        let editText = store.state.editTexts.find(editText => editText.id == editId)
        const update = accept ? editText.insert : editText.delete;
        
        document = document.replace(editBlockPattern, update);

        eventBus.emit('Edit.Applied', document);
      },
      deleteEdit(editId) {
        const editBlockPattern = new RegExp(`::: edit \\[${editId}\\][\\s\\S]*?:::`, 'g');
        let content = store.state.activeItem.content;
        
        content = content.replace(editBlockPattern, '');
        
        eventBus.emit('Edit.Applied', content);
      },
    }
  };
  </script>
  
  <style>
.edit-block {
  position: relative;
  border: 1px solid #e4e9f0;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 4px;
}

.delete-button {
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
}

.controls {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.controls button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #3c7e6dd9;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.controls button:hover {
  background-color: #3c7e6d;
}

.controls button:disabled {
  background-color: #e4e9f0;
  color: #aaa;
}

.delete-content {
  background-color: #ffebee; /* Light red */
}

.insert-content {
  background-color: #e8f5e9; /* Light green */
}

.delete {
  color: red;
  white-space: pre-wrap !important;
}

.insert {
  color: green;
}

.accept-btn,
.reject-btn {
  padding: 5px 10px;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.accept-btn {
  background-color: #4CAF50;
  color: white;
}

.reject-btn {
  background-color: #f44336;
  color: white;
}


  </style>