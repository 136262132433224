<template>
      <button 
      class="export-btn" 
      @click="toggleMenu"
      aria-haspopup="true" 
      aria-controls="overlay_tmenu"
      tabindex="0" >
        Export...
    </button>
    <TieredMenu ref="menu" :model="menuItems" popup />
    <iframe id="print-iframe" style="position: absolute; width: 0; height: 0; border: none;"></iframe>
</template>

<script>
import { store } from '../../../services/store.js'
import { eventBus } from '../../../services/eventBus';
import { sendExportRequest } from '../../../services/apiService.js'
import { useAuth0 } from '@auth0/auth0-vue';

export default {
name: 'ExportMenu',
setup() {
  const { getAccessTokenSilently } = useAuth0();
  return { getAccessTokenSilently };
},
data() {
    return {
      store,
      menuItems: [
      { label: 'Copy', icon: 'pi pi-fw pi-copy', command: this.copyHtmlToClipboard },
      { label: 'PDF', icon: 'pi pi-fw pi-file-export', command: () => this.exportFile('pdf') },
      { label: 'Word', icon: 'pi pi-fw pi-file-export', command: () => this.exportFile('docx') },
      { label: 'Markdown', icon: 'pi pi-fw pi-file-export', command: () =>  this.exportFile('md') },
      ]
    }
  },
components: {
},

methods: {
  toggleMenu() {
      this.$refs.menu.toggle(event);
    },
    async copyHtmlToClipboard() {
    const html = this.store.editor.getHTML();
    const blob = new Blob([html], { type: 'text/html' });
    const data = [new ClipboardItem({ 'text/html': blob })];
    await navigator.clipboard.write(data);
    },
  generatePDF() {
    this.$nextTick(() => {
      const editorContentElement = document.querySelector('.editor-content');
      const styleSheets = Array.from(document.styleSheets)
        .filter(sheet => !sheet.href || sheet.href.startsWith(window.location.origin))
        .reduce((acc, sheet) => acc + Array.from(sheet.cssRules || []).map(rule => rule.cssText).join('\n'), '');

      const content = editorContentElement.innerHTML;
      const iframe = document.getElementById('print-iframe');
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      const additionalStyles = `body, .${editorContentElement.className} { overflow: hidden; }`;

iframeDoc.head.innerHTML = `<style>${styleSheets}\n${additionalStyles}</style>`;
      iframeDoc.body.innerHTML = `<div class="${editorContentElement.className}">${content}</div>`;

      iframe.contentWindow.print();
      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
  },
  async exportFile(fileType) {
    const token = await this.getAccessTokenSilently();
    try {

      const fileNameNoExt = store.state.activeItem.name.replace(/\.\w+$/, '');

      if (fileType === 'pdf') {
        this.generatePDF();
        eventBus.emit('Toast.Show', { message: 'Exported as PDF', type: 'success' });
      }
      else if (fileType === 'md') {
        const content = store.state.activeItem.content;
        const blob = new Blob([content], { type: 'text/markdown' });
        const fileName = fileNameNoExt + '.md';
        this.downloadBlob(blob, fileName);
        eventBus.emit('Toast.Show', { message: 'Exported as Markdown', type: 'success' });
      }
      else {
        const content = store.state.activeItem.content;
        const blob = await sendExportRequest(token, content, fileType, 'md');
        const fileName = fileNameNoExt + '.' + fileType;
        this.downloadBlob(blob, fileName);
        eventBus.emit('Toast.Show', { message: 'Exported as Word Doc', type: 'success' });
      }

    } catch (error) {
      console.error('Error exporting file:', error);
      eventBus.emit('Toast.Show', { message: 'Error Exporting File', type: 'error' });
    }
    finally {
      this.shareModal = false;
    }
  },
  downloadBlob(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  },
 

  
},
}
</script>
<style scoped>

.export-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
    width: 75px
}


</style>