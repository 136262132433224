<template>
      <div class="panel-content">
        <div class="top">
        <h3 style="font-size:20px; font-weight: 500; margin: 5px 0 ;">Reference</h3>
        <p style="font-size: 13px; margin-top:0px">Add read-only references for the AI</p>

        <div class="reference-list"> 
          <div v-for="(reference, index) in store.state.references" :key="reference.id" class="wrapper">
            <ReferenceItem :index="index" />
          </div>
      </div>
      <div class="panel-footer">
        <AddReferenceMenu />
        </div>
        <PvDivider></PvDivider>
        <h3 style="font-size:20px; font-weight: 500; margin: 5px 0 ;">Create</h3>
        <p style="font-size: 13px; margin-top:0px">Generate and edit documents with AI </p>
      <div class="reference-list"> 

        <div v-for="(document, index) in store.state.documents" :key="document.id" class="wrapper">
            <DocumentItem :index="index" />
          </div>
      </div>
<div class="panel-footer">
        <AddDocumentMenu />
        </div>
        </div>
        <div class="bottom">
          <div :class="{ 'word-count-exceeded ': wordCount > this.store.state.model.wordLimit }">
          Word Count: {{ wordCount }} / {{ store.state.model.wordLimit }}
        </div>
    </div>
      </div>

</template>

<script>
import AddReferenceMenu from './AddReferenceMenu.vue';
import AddDocumentMenu from './AddDocumentMenu.vue';
import { store, setActiveItem,wordCount} from '../../services/store.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt, faPlus,faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import ReferenceItem from './ReferenceItem.vue';
import DocumentItem from './DocumentItem.vue';

library.add(faFileAlt, faPlus, faTrashCan, faEdit, faCheckSquare, faSquare);

export default {
  name: 'ReferencesPanel',
  components: {
    AddReferenceMenu,
    AddDocumentMenu,
    ReferenceItem,
    DocumentItem
  },
  data() {
    return {
      showAddManuallyModal: false,
      isAccordionOpen: false,
      store,
      manualText: '',
      manualName: '',
      currentEditIndex: null ,
      wordCount
    };
  },
  methods: {
    setActiveItem,
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    isActiveItem(id) {
      if (store.state.activeItem & store.state.activeItem.id)
        return store.state.activeItem.id === id;

        return false;
      },
  },

};
</script>

<style scoped>

.word-count-exceeded {
  color: red;
}

.import-file-btn {
  align-self: flex-start;
  margin-left: -10px;
}

 .panel-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.reference-list {
  overflow-y: auto;
  /* flex-grow: 1; */
}

.top {
  overflow-y: auto;
}


.panel-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}


.add-reference-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  background-color: #3c7e6db0;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-reference-button:hover {
  background-color: #3c7e6d;
}




.add-icon {
  font-size: 1rem;
}

.file-icon {
  font-size: 1.5rem;
  color: #3c7e6dbf;
}

.file-icon.active {
  color: #999;
}

.reference-info {
  overflow: hidden;
  line-break: anywhere;
}

.reference-name {
  font-size: 0.75rem;
  color: #333;
  margin-top: 5px;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.reference-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  margin: 10px 0;
  min-height: 90px;
  /* box-shadow: 0px 2px 4px rgba(0,0,0,0.1); */
}

.reference-card.active {
  background-color: #f7f8fa;
}
.reference-card:hover {
  background-color: #3c7e6d24;
}

.reference-button {
  margin-top: 8px;
  padding-top: 2px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.reference-button:hover {
  color: #333;
}

.reference-button.active {

border: none;
margin: 0;
cursor: pointer;
transition: background-color 0.3s ease;
flex: 1;
border-radius: 7px;
background-color: #3c7e6dbf;
color: white;
}




.message-type-selector {
  margin-bottom: 2px;
}

.message-type-selector button {
  padding: 6px 10px;
  border: none;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f7f8fa;
  color: #a0a0a0;
  flex: 1;
  border-radius: 7px
}

.message-type-selector button {
  position: relative;
}


</style>