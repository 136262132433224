<template>
  <div class="message-input-container">
    <div class="message-type-selector">
      <button v-tooltip.top="{ value: 'Chat', showDelay: 50}" :class="{ active: !edit }" class="left" @click="toggleEdit()">
      <FontAwesomeIcon :icon="['fas', 'comment']" /> Chat
      </button>
      <button v-tooltip.right="{ value: 'Create content with AI', showDelay: 50}" :disabled="!isDocument()" :class="{ active: edit }" class="right"  @click="toggleEdit()">
      <FontAwesomeIcon :icon="['fas', 'wand-magic-sparkles']" /> Create
    </button>
      </div>
    <div class="input-with-button">
      <TextArea unstyled v-model="messageText" auto-resize :disabled ="isSending" :placeholder="getPlaceholder()" class="chat-input" @keyup.enter="send($event)"></TextArea>
      <div class="send-button-container">
      <button class="send-button" :disabled="isSending" @click="send" v-tooltip.top="{ value:wordCount > store.state.model.wordLimit? 'Word count exceeded' : 'Send', showDelay: 300}">
        <div :class="{ 'word-count-exceeded ': wordCount > store.state.model.wordLimit }">
        <FontAwesomeIcon  :icon="['fas', 'paper-plane']" />
        </div>
      </button>
      </div>
    </div>
    <!-- <button class="send-button" :class="{ 'cancel-button': isSending }" @click="isSending ? cancel() : send()">
      {{ isSending ? 'Cancel' : 'Send' }}
    </button> -->
  </div>
</template>

<script>
import { documentPrompts } from '../../data/documentPrompts';
import { store, refreshActiveItemData, wordCount } from '../../services/store.js';

export default {
  name: 'MessageInput',
  props: {
    isSending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      messageText: '',
      edit: false,
      placeholderIndex: 0,
      placeholder: documentPrompts[0],
      promptInterval: null,
      wordCount,
      store
    };
  },
  emits: ['sendMessage'],
  mounted() {
    this.placeholderIndex = Math.floor(Math.random() * documentPrompts.length);
    this.promptInterval = setInterval(this.rotatePlaceholder, 4000);
  },
  beforeUnmount() {
    clearInterval(this.promptInterval);
  },
  methods: {
    isDocument() {
    if (store.state.activeItem && store.state.activeItem.type)
      return store.state.activeItem.type.startsWith('document');

    return false
  },
    rotatePlaceholder() {
      this.placeholderIndex = (this.placeholderIndex + 1) % documentPrompts.length;
      this.placeholder = documentPrompts[this.placeholderIndex];
    },
    getPlaceholder() {
        return !this.edit ? 'Discuss content with AI...' : 'Ask AI to write to the selected document...'
    },
    // rotatePlaceholder() {
    //   this.placeholderIndex = (this.placeholderIndex + 1) % documentPrompts.length;
    //   this.placeholder = documentPrompts[this.placeholderIndex];
    // },
    toggleEdit() {
      this.edit = !this.edit;
    },
    send(event) {
      // Check if Shift key is pressed along with Enter or if isAiResponding is true, if so, do not send the message
      if ((event && event.shiftKey) || this.isSending) {
          return;
      }
      refreshActiveItemData();

      let messageType = 'question';

      if (this.isDocument() && this.edit) {
        messageType = 'edit';
      }

      this.$emit('sendMessage', {
        message: this.messageText,
        requestType: messageType
      });
      this.messageText = ''; 
    },
    cancel() {
      store.streamData.cancel = true;
      store.request.abortController.abort();
    }
  }
};
</script>

<style scoped>

.word-count-exceeded { 
  color: red
}

.message-input-container {
  display: flex;
  flex-direction: column;
}

.input-with-button {
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 4px;

}

.chat-input {
  overflow-y: scroll !important;
  border: none;
  outline:none;
  max-height: 90px;
  border: 1px solid #ccc;
  border-radius:inherit;
  resize: none; 
  font-family: inherit;
  font-size: inherit;
  flex-grow: 1;
  padding: 10px;
}

.send-button-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-left: -50px;
  border-radius:inherit;
}

.send-button {
  height:100%;
  width:100%;
  border: 0.5px solid #d2d5da;
  border-left: none;
  border-radius: inherit;
    background: none;
    color: #3c7e6d;
    font-size: large;
}

.send-button[disabled] {
  cursor: default;
  opacity: 0.5;
}

.send-button:hover:not([disabled]) {
  /* background-color: #f7f8fa; */
  cursor:pointer;
}

.cancel-button {
  background-color: #f7f8fa;
  color: black;
  border: 0.5px solid #d2d5da;
}

.cancel-button:hover {
  color: white
}

.message-type-selector {
  margin-bottom: 2px;
}

.message-type-selector button {
  padding: 6px 10px;
  border: none;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f7f8fa;
  color: #333;
  flex: 1;
}

.message-type-selector .left {
  border-radius: 7px 0 0 7px;
}

.message-type-selector .right {
  border-radius: 0 7px 7px 0;
}

.message-type-selector button.active {
  background-color: #3c7e6d;
  color: white;
}

.message-type-selector button {
  position: relative;
}

.message-type-selector button:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0; /* Gray background to imply it's disabled */
  color: #a0a0a0; /* Dim text color to further imply the button is disabled */
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .chat-input select,
  .chat-input textarea,
  .chat-input input {
    font-size: 16px;
  }
}
</style>