<template>
  <div class="document-item" >
    <div class="document-card" :class="{ 'active': store.state.activeItem && store.state.activeItem.id === this.document.id}" @click="setDocument">
      <div class="document-info">
        <FontAwesomeIcon :icon="['fas', 'file-alt']" class="file-icon" />
        <PvInplace :closable="true" @close="updateActiveName">
          <template #display> {{ document.name }}          
          </template>
          <template #content>
              <InputText class="name-input" v-model="document.name" autofocus />
          </template>
        </PvInplace>
      </div>
      <div class="document-buttons">
        <button class="document-button" @click="deleteDocument">
          <FontAwesomeIcon :icon="['far', 'trash-can']" />
        </button> 
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { store, setActiveItem } from '../../services/store.js';

export default {
  name: 'DocumentItem',
  components: {
    FontAwesomeIcon
  },
  props: {
    index: Number
  },
  data () {
  return {
    store,
    document: store.state.documents[this.index],
  }
},
  methods: {
    setDocument(){
      setActiveItem(this.document.id);
    },
    updateActiveName() {
        if (store.state.activeItem && store.state.activeItem.id === this.document.id) {
          store.state.activeItem.name = this.document.name;
        }     
      },
      deleteDocument() {
      if (!store.aiInProgress){ 
        this.store.state.documents.splice(this.index, 1);
       this.store.state.activeItem = null;
      }
    }
  }
};
</script>

<style>
.document-info .p-inplace .p-inplace-display {
  padding: 0;
  border-radius: 0;
  transition: none;
  box-shadow: none;
  font-size: 0.75rem;
}

.document-info .p-inputtext {
  box-shadow: none; 
  font-size: 0.75rem;   
   height: 30px;
}

.document-info .p-button {
  box-shadow: none; 
  font-size: 0.75rem;
  height: 30px;
  width: 30px;
}

</style>

<style scoped>

.file-icon {
  font-size: 1.5rem;
  color: #3c7e6dbf;
}

.file-icon.active {
  color: #999;
}

.document-info {
  overflow: hidden;
  line-break: anywhere;
}

.document-name {
  font-size: 0.75rem;
  color: #333;
  margin-top: 5px;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.document-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  margin: 10px 0;
  min-height: 90px;
}

.document-card.active {
  background-color: #f7f8fa;
}
.document-card:hover {
  background-color: #3c7e6d24;
}

.document-button {
  margin-top: 8px;
  padding-top: 2px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.document-button:hover {
  color: #333;
}
</style>