<template>
  <div class="centre-panel-container">
    <DocumentEditor v-if="isDocument()" ref="documentEditor"/>
    <textarea :disabled="!store.state.activeItem.editing" v-else-if="store.state.activeItem" v-model="store.state.activeItem.content" class="raw-markdown-editor"></textarea>
    <div  class="button-container">

    <!-- <button class="btn" @click="shareModal = true">Share...</button> -->

    <!-- <div v-if="saveStatus" class="btn save-status">{{ saveStatus }}</div> -->
  </div>
</div>
</template>

<script>
import DocumentEditor from './DocumentEditor/DocumentEditor.vue'
import { store } from '../../services/store.js'
// import { eventBus } from '../../services/eventBus';
// import { sendImportRequest } from '../../services/apiService.js'
import { useAuth0 } from '@auth0/auth0-vue';

export default {
name: 'FileHandler',
setup() {
  const { getAccessTokenSilently } = useAuth0();
  return { getAccessTokenSilently };
},
data() {
  return {
    saveStatus: '',
    fileHandle: null,
    store,
  };
},
components: {
  DocumentEditor
},
methods: {
  isDocument() {
    if (store.state.activeItem && store.state.activeItem.type)
      return store.state.activeItem.type.startsWith('document');

    return false
  }
  },
  activeItemContent() {
    if (store.state.activeItem.value) 
      return store.state.activeItem.content
    else return ''
  }
}
</script>
302 <style scoped>

.centre-panel-container {
flex: 1;
display: flex;
font-family: 'Roboto', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
flex-direction: column;
}

input[type="file"] {
margin: 20px 0;
padding: 10px;
border: none;
border-bottom: 2px solid #5C6BC0;
border-radius: 0;
cursor: pointer;
transition: border-color 0.3s ease;
}

.file-name { 
text-align: center;
font-weight: bold;
}

.button-container { 
padding: 0 30px;
display: flex;
justify-content: space-around;
}

.dropdown {
position: relative;
display: inline-block;
}

.btn {
width: 175px;
padding: 10px 20px;
margin: 0 20px;
border: 0.5px solid #d2d5da;
border-radius: 5px;
background-color: #f7f8fa;
color: black;
cursor: pointer;
transition: background-color 0.3s ease;
}

.btn:hover {
background-color: #3c7e6dd9;
color: white;
box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}





</style>