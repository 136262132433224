// This file abstracts the API requests to the backend and exposes a method for streaming conversation steps.

// const baseUrl = 'http://localhost:8000';
const baseUrl = 'https://document-generator-api-prod.azurewebsites.net';
import { store } from './store.js'

export async function sendImportRequest(token, file) {
  const formData = new FormData();
  formData.append('file', file);

  const endpoint = '/file/import';

  const response = await fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function sendExportRequest(token, content, fileType, inputType) {
  if (!['pdf', 'docx'].includes(fileType)) {
    throw new Error('Unsupported file type specified.');
  }

  const endpoint = '/file/export';

  const response = await fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: content,
      file_type: fileType,
      input_type: inputType
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.blob();
}


export async function getModels(token) {
  const endpoint = '/models';
  const response = await fetch(baseUrl + endpoint, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function streamConversationStep(token, request) {
  const requestId = crypto.randomUUID();
  const requestBody = JSON.stringify(request);
  const endpoint = '/conversation/next';
  const controller = new AbortController();
  const signal = controller.signal;
  
  store.request.id = requestId;
  store.request.timeStamp = new Date();
  store.request.body = requestBody;
  store.request.type = request.messages[request.messages.length - 1].requestType;
  store.request.endpoint = endpoint;
  store.request.abortController = controller;

  const response = await fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'X-Request-ID': requestId
    },
    body: requestBody,
    signal:signal 

  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response;
}

export async function sendRequestLog(token, request) {
  const response = await fetch(baseUrl + '/conversation/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request)
  });
  return response;
}

export async function getAccountDetails(token) {
  const endpoint = '/account';
  const response = await fetch(baseUrl + endpoint, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function extractWebData(token, url) {
  const endpoint = '/data_extract/web_html2text';
  const response = await fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ url: url }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}

export async function requestMoreCredits(token) {
  const endpoint = '/account/credit/requestMore';
  const response = await fetch(baseUrl + endpoint, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
}