<template>
    <div v-if="isOpen" class="table-grid-selector" @click.self="closeSelector">
      <div class="grid" @mouseleave="resetSelection">
        <div v-for="row in 10" :key="row" class="grid-row">
          <div v-for="col in 10" :key="col" class="grid-cell" :class="{ 'selected': isSelected(row, col) }" @mousemove="updateSelection(row, col)" @click="confirmSelection"></div>
        </div>
      </div>
      <svg class="table-grid-selector-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 10">
        <path d="M0,10 L10,0 L20,10 Z" fill="white"/>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isOpen: {
            type: Boolean,
            required: true
        }
    },
    data() {
      return {
        rows: 0,
        cols: 0,
      };
    },
    methods: {
      updateSelection(row, col) {
        this.rows = row;
        this.cols = col;
      },
      isSelected(row, col) {
        return row <= this.rows && col <= this.cols;
      },
      confirmSelection() {
        if (this.rows > 0 && this.cols > 0)
          this.$emit('insert-table', { rows: this.rows, cols: this.cols });
        this.closeSelector();
      },
      closeSelector() {
        this.$emit('close');
      },
      resetSelection() {
        this.rows = 0;
        this.cols = 0;
      },
    },
  };
  </script>
  
  <style scoped>
.table-grid-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border: 1px solid #e4e9f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  top: 35px;
  left: -13px;
  z-index: 1;
}

.table-grid-selector.show {
  display: flex; /* Show when active */
}

.table-grid-selector-arrow {
  position: absolute;
  top: -10px;
  left: 30px;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(10, 20px);
  grid-template-rows: repeat(10, 20px);
  gap: 5px;
}
.grid-row {
  display: contents;
}
.grid-cell {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  background-color: #f7f8fa;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.grid-cell.selected {
  background-color: #3c7e6dd9;
  border-color: #3c7e6d;
}
  </style>